import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { colors } from '../../theme/colors';
import { hexToRgbA } from '../../utils/helper';
import { Body, Headline } from '../Texts/Texts';
import SeverityTag from './SeverityTag';
import SymptomsTag from './SymptomsTag';
import { useNavigate } from 'react-router-dom';

interface CaseCardProps {
  data: any;
  index: string | number;
  items?: any;
}

const CaseCard = ({ data, index, items }: CaseCardProps) => {
  const navigate = useNavigate();
  const caseType = () => {
    switch (data.severity.toLowerCase()) {
      case 'moderate':
        return {
          bg: hexToRgbA(colors.accent, '0.1')
        };
      case 'moderately_severe':
        return {
          bg: hexToRgbA(colors.warning, '0.1')
        };
      case 'severe':
        return {
          bg: hexToRgbA(colors.error, '0.1')
        };
      default:
        return {
          bg: hexToRgbA(colors.gray, '0.1')
        };
    }
  };
  return (
    <Box
      bg={caseType().bg}
      p='8px 8px 12px 16px'
      borderRadius='8px'
      cursor='pointer'
      onClick={() =>
        navigate(`/cases/${data._id}`, {
          state: { items, index }
        })
      }
    >
      <Flex justifyContent='flex-end'>
        <SeverityTag type={data.severity} right='8px' />
      </Flex>
      <Headline color='deep_blue' size='19px' fontWeight={500} mb='4px'>
        {data.user.name}
      </Headline>
      <Body color='gray' size='14px' mb='8px'>
        {data.user.ageGroup}yrs • {data.user.gender}
      </Body>
      <Flex>
        {/* {data.labels.map((label: string, index: number) => (
          <SymptomsTag text={label} mr="8px" key={label + index} />
        ))} */}
        <SymptomsTag text={data.symptom} mr='8px' />
      </Flex>
    </Box>
  );
};

export default CaseCard;
