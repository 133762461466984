import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react';

interface ModalProps {
  onClose: () => void;
  isOpen: boolean;
  children: any;
  maxW: string;
  bg?: string;
  noCloseBtn?: boolean;
}

const ModalView = ({
  onClose,
  isOpen,
  maxW,
  bg,
  children,
  noCloseBtn
}: ModalProps) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent maxW={maxW} bg={bg || 'white'}>
        {!noCloseBtn && (
          <ModalCloseButton
            bg='shade'
            boxSize='26px'
            borderRadius='13px'
            color='muted'
          />
        )}
        {children}
      </ModalContent>
    </Modal>
  );
};

export default ModalView;
