export const mediaTypes = {
  imageTypes: ['image/jpeg', 'image/png'],
  audioTypes: [
    'audio/aac',
    'audio/mp4',
    'audio/mpeg',
    'audio/amr',
    'audio/ogg'
  ],
  videoTypes: ['video/mp4', 'video/3gp'],
  stickerTypes: ['image/webp'],
  documentTypes: [
    'text/plain',
    'application/pdf',
    'application/vnd.ms-powerpoint',
    'application/msword',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ]
};

export const fileSizes = {
  audio: '16 MB',
  document: '100 MB',
  image: '5 MB',
  sticker: '100 KB',
  video: '16 MB',
  text: undefined
};
