import { Box, Flex, Grid } from '@chakra-ui/react';
import { useState, useEffect, useContext } from 'react';
import { getFromUrl } from '../../api/Api';
import Card from '../../components/Dashboard/Card';
import DefaultCards from '../../components/Dashboard/DefaultCard';
import SymptomsChart from '../../components/Dashboard/SymptomsChart';
import TimeCard from '../../components/Dashboard/TimeCard';
import TotalUnassignedCard from '../../components/Dashboard/TotalUnassignedCard';
import UnassignedSeverityCard from '../../components/Dashboard/UnassignedSeverityCard';
import { CasesOverview, Indexable } from '../../interface';
import DashboardLayout from '../../layouts/dashboard/DashboardLayout';
import { colors } from '../../theme/colors';
import DashboardSkeleton from '../../components/Skeletons/DashboardSkeleton';
import { capitalize } from '../../utils/helper';
import { AuthContext } from '../../context/AuthContext';

const Dashboard = () => {
  interface GraphInterface {
    name: string;
    value: number;
  }

  interface Count {
    counsellors: number;
    clients: number;
    female: number;
    male: number;
  }

  const { signOut } = useContext(AuthContext);
  const [data, setData] = useState([] as GraphInterface[]);
  const [casesOverview, setCasesOverview] = useState({} as CasesOverview);
  const [usersOverview, setUsersOverview] = useState({} as Count);
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    const cachedUser = localStorage.getItem('user');
    if (cachedUser) setUser(JSON.parse(cachedUser));

    getUserOverview().catch((err) => console.error(err));
    getCasesOverview().catch((err) => console.error(err));
  }, []);

  const getUserOverview = async () => {
    try {
      const overview = await getFromUrl('users/overview');
      if (overview?.status === 401) signOut();
      if (typeof overview === 'object') {
        setUsersOverview(overview?.count);
      }
    } catch (e: any) {
      return;
    }
  };

  const getCasesOverview = async () => {
    try {
      const overview = await getFromUrl('cases/overview');
      if (overview?.status === 401) signOut();
      if (typeof overview === 'object') {
        setCasesOverview(overview);
        createGraphData(overview?.prevalentSymptoms);
      }
    } catch (e: any) {
      return;
    }
  };

  const { cases, severity } = casesOverview || {};

  const createGraphData = (object: any) => {
    const graphData = [];
    for (const key in object) {
      const item = {
        name: key,
        value: (object as Indexable)[key]
      };
      graphData.push(item);
    }
    setData(graphData);
  };
  return (
    <DashboardLayout
      pageTitle={`Welcome ${user ? capitalize(user?.name?.split(' ')[0]) : ''}`}
    >
      {usersOverview &&
      casesOverview &&
      Object.keys(usersOverview).length &&
      Object.keys(casesOverview).length ? (
        <Grid gridTemplateColumns='1fr 2.4fr' gap='40px' pb='54px'>
          <Box>
            <TotalUnassignedCard no={cases.unassigned} />
            <Card h='108px' mb='16px'>
              <DefaultCards
                no={cases.escalated}
                text='Escalated Cases'
                color={colors.error}
                icon='Danger'
              />
            </Card>
            <Card h='108px' mb='16px'>
              <DefaultCards
                no={casesOverview.sessions.active}
                text='Active Sessions'
                color={colors.dark_green}
                icon='Folder'
              />
            </Card>
            <Card h='108px' mb='16px'>
              <DefaultCards
                no={casesOverview.sessions.ongoing}
                text='Ongoing Sessions'
                color={colors.deep_blue}
                icon='Folder'
              />
            </Card>
          </Box>
          <Box pr='70px'>
            <UnassignedSeverityCard severity={severity} />
            <Grid gridTemplateColumns='1fr 1fr' gap='24px' mb='16px'>
              <Card h='80px' px='24px'>
                <DefaultCards
                  no={usersOverview.counsellors}
                  text='Total Counsellors'
                  color={colors.deep_blue}
                  icon='Profile'
                  bold
                />
              </Card>
              <Card h='80px' px='24px'>
                <DefaultCards
                  no={usersOverview.clients}
                  text='Total Clients'
                  color={colors.default_green}
                  icon='Profile'
                  bold
                />
              </Card>
            </Grid>
            <Flex mb='16px'>
              <TimeCard
                no={
                  typeof casesOverview?.averageWaitTimeInHours === 'number'
                    ? casesOverview?.averageWaitTimeInHours
                    : 0
                }
              />
              <Box w='24px' />
              <SymptomsChart data={data} />
            </Flex>
          </Box>
        </Grid>
      ) : (
        <DashboardSkeleton />
      )}
    </DashboardLayout>
  );
};

export default Dashboard;
