import { Box, Button, Flex, Grid } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { getFromUrl } from '../api/Api';
import Card from '../components/Dashboard/Card';
import DefaultCard from '../components/Analytics/DefaultCard';
import SymptomsChart from '../components/Analytics/SymptomsChart';
import TimeCard from '../components/Dashboard/TimeCard';
import UnassignedSeverityCard from '../components/Dashboard/UnassignedSeverityCard';
import { CasesOverview } from '../interface';
import DashboardLayout from '../layouts/dashboard/DashboardLayout';
import { colors } from '../theme/colors';
import NationalityRatio from '../components/Analytics/NationalityRatio';
import GenderRatioChart from '../components/Analytics/GenderRatioChart';
import NumberOfCases from '../components/Analytics/NumberOfCases';
import { createGraphData } from '../utils/helper';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import DashboardSkeleton from '../components/Skeletons/DashboardSkeleton';
import useRangeChange from 'hooks/useRangeChange';
import AgeRatioChart from 'components/Analytics/AgeRatioChart';

const Analytics = () => {
  interface GraphInterface {
    name: string;
    value: number;
  }

  interface Count {
    counsellors: number;
    clients: number;
    female: number;
    male: number;
  }

  const [data, setData] = useState([] as GraphInterface[]);
  const [casesOverview, setCasesOverview] = useState({} as CasesOverview);
  const [usersOverview, setUsersOverview] = useState({} as Count);
  const [isDownloading, setIsDownloading] = useState(false);
  const { range, handleChange } = useRangeChange();

  React.useEffect(() => {
    getUserOverview();
    getCasesOverview();
  }, [range]);

  const getUserOverview = async () => {
    try {
      const overview = await getFromUrl('users/overview');
      setUsersOverview(overview.count);
    } catch (e: any) {
      return;
    }
  };

  const getCasesOverview = async () => {
    try {
      const overview = await getFromUrl(`cases/overview?range=${range}`);
      setCasesOverview(overview);
      const graphData = createGraphData(overview?.prevalentSymptoms);
      setData(graphData);
    } catch (e: any) {
      return;
    }
  };

  const { cases, severity } = casesOverview || {};

  const handleDownload = () => {
    setIsDownloading(true);
  };
  useEffect(() => {
    if (isDownloading) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      html2canvas(document.querySelector('#capture')! as HTMLElement).then(
        (canvas) => {
          const image = canvas.toDataURL('image/png', 1.0);
          const doc = new jsPDF();
          doc.addImage(image, 'png', 15, 40, 160, 200);
          doc.save('itunu-analytics.pdf');
        }
      );
      setIsDownloading(false);
    }
  }, [isDownloading]);

  return (
    <DashboardLayout pageTitle={`Analytics`}>
      {Object.keys(usersOverview).length &&
      Object.keys(casesOverview).length ? (
        <>
          <Button
            bg={colors.accent}
            my='14px'
            color={colors.white}
            onClick={handleDownload}
          >
            Download
          </Button>
          <Grid
            gridTemplateColumns='1fr 2.4fr'
            gap='40px'
            pb='54px'
            id='capture'
            mx={isDownloading ? 'auto' : ''}
          >
            <Box>
              <NumberOfCases isDownloading={isDownloading} />
              {
                <Card
                  h='108px'
                  mb='16px'
                  bg={isDownloading ? colors.light_green : colors.white}
                >
                  <DefaultCard
                    isDownloading={isDownloading}
                    no={cases?.unassigned}
                    text='Unassigned Cases'
                    color={colors.deep_blue}
                    icon='Activity'
                  />
                </Card>
              }
              {cases ? (
                <Card
                  h='108px'
                  mb='16px'
                  bg={isDownloading ? colors.light_green : colors.white}
                >
                  <DefaultCard
                    isDownloading={isDownloading}
                    no={cases?.escalated}
                    text='Escalated Cases'
                    color={colors.error}
                    icon='Danger'
                  />
                </Card>
              ) : (
                <span></span>
              )}
              <Card
                h='108px'
                mb='16px'
                bg={isDownloading ? colors.light_green : colors.white}
              >
                <DefaultCard
                  isDownloading={isDownloading}
                  no={casesOverview.sessions.active}
                  text='Active Sessions'
                  color={colors.dark_green}
                  icon='Folder'
                />
              </Card>
              <Card
                h='108px'
                mb='16px'
                bg={isDownloading ? colors.light_green : colors.white}
              >
                <DefaultCard
                  isDownloading={isDownloading}
                  no={casesOverview.sessions.ongoing}
                  text='Ongoing Sessions'
                  color={colors.deep_blue}
                  icon='Folder'
                />
              </Card>
              <GenderRatioChart />
              <AgeRatioChart />
            </Box>
            <Box pr='70px'>
              <UnassignedSeverityCard
                severity={severity}
                isDownloading={isDownloading}
              />
              {Object.keys(usersOverview).length ? (
                <Grid gridTemplateColumns='1fr 1fr' gap='24px' mb='16px'>
                  <Card
                    h='80px'
                    px='24px'
                    bg={isDownloading ? colors.light_green : colors.white}
                  >
                    <DefaultCard
                      isDownloading={isDownloading}
                      no={usersOverview.counsellors}
                      text='Total Counsellors'
                      color={colors.deep_blue}
                      icon='Profile'
                      bold
                    />
                  </Card>
                  <Card
                    h='80px'
                    px='24px'
                    bg={isDownloading ? colors.light_green : colors.white}
                  >
                    <DefaultCard
                      isDownloading={isDownloading}
                      no={usersOverview.clients}
                      text='Total Clients'
                      color={colors.default_green}
                      icon='Profile'
                      bold
                    />
                  </Card>
                </Grid>
              ) : (
                <span></span>
              )}
              <Flex mb='16px' w='100%' justifyContent='space-between'>
                <SymptomsChart
                  data={data}
                  isDownloading={isDownloading}
                  handleChange={handleChange}
                />
                <TimeCard
                  no={
                    typeof casesOverview?.averageWaitTimeInHours === 'number'
                      ? casesOverview?.averageWaitTimeInHours
                      : 0
                  }
                />
              </Flex>
              <NationalityRatio />
            </Box>
          </Grid>
        </>
      ) : (
        <DashboardSkeleton />
      )}
    </DashboardLayout>
  );
};

export default Analytics;
