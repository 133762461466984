import { Box } from '@chakra-ui/react';
import { colors } from '../theme/colors';

interface IconProps {
  name: string;
  color?: string;
  size?: number;
  filled?: boolean;
  [x: string]: any;
}

export const Icon = ({ name, color, size, filled, ...rest }: IconProps) => (
  <Box
    as='i'
    className={filled ? `iconly-${name} icbo` : `iconly-${name} icli`}
    style={{
      color: color ? color : colors.gray,
      fontSize: size ? `${size}px` : '24px',
      display: 'block'
    }}
    {...rest}
  />
);

export const Check = ({
  color,
  iconSize,
  ...rest
}: {
  color?: string;
  iconSize?: string;
  [x: string]: any;
}) => (
  <Box
    as='span'
    className='iconlyBulk-Shield-Done'
    fontSize={iconSize || '36px'}
    {...rest}
  >
    <Box as='span' className='path1' display='none'></Box>
    <Box as='span' className='path2' color={color || 'dark_green'}></Box>
  </Box>
);

export const Clip = () => (
  <svg
    width='11'
    height='22'
    viewBox='0 0 11 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9.5 5V16.5C9.5 18.71 7.71 20.5 5.5 20.5C3.29 20.5 1.5 18.71 1.5 16.5V4C1.5 3.33696 1.76339 2.70107 2.23223 2.23223C2.70107 1.76339 3.33696 1.5 4 1.5C4.66304 1.5 5.29893 1.76339 5.76777 2.23223C6.23661 2.70107 6.5 3.33696 6.5 4V14.5C6.5 15.05 6.05 15.5 5.5 15.5C4.95 15.5 4.5 15.05 4.5 14.5V5H3V14.5C3 15.163 3.26339 15.7989 3.73223 16.2678C4.20107 16.7366 4.83696 17 5.5 17C6.16304 17 6.79893 16.7366 7.26777 16.2678C7.73661 15.7989 8 15.163 8 14.5V4C8 1.79 6.21 0 4 0C1.79 0 0 1.79 0 4V16.5C0 19.54 2.46 22 5.5 22C8.54 22 11 19.54 11 16.5V5H9.5Z'
      fill='#2C2738'
    />
  </svg>
);

export const Mic = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12 16C14.206 16 16 14.206 16 12V6C16 3.783 14.215 1.979 12.021 1.979C11.9506 1.97943 11.8805 1.98781 11.812 2.004C10.7853 2.05378 9.81693 2.49636 9.10738 3.24016C8.39783 3.98395 8.00136 4.97205 8 6V12C8 14.206 9.794 16 12 16Z'
      fill='white'
    />
    <path
      d='M11 19.931V22H13V19.931C16.939 19.436 20 16.073 20 12H18C18 15.309 15.309 18 12 18C8.691 18 6 15.309 6 12H4C4 16.072 7.061 19.436 11 19.931Z'
      fill='white'
    />
  </svg>
);

export const Smiley = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g opacity='0.5'>
      <path
        d='M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20Z'
        fill='#2C2738'
      />
      <path
        d='M14.8289 14.8281C14.4634 15.1924 14.0317 15.4836 13.5569 15.6861C12.7022 16.0469 11.7487 16.0996 10.8595 15.8351C9.97022 15.5706 9.20049 15.0054 8.68193 14.2361L7.02393 15.3551C7.45636 15.9932 8.00652 16.543 8.64493 16.9751C9.29458 17.4144 10.0245 17.7213 10.7929 17.8781C11.5897 18.0406 12.4111 18.0406 13.2079 17.8781C13.9763 17.721 14.7062 17.4142 15.3559 16.9751C15.6689 16.7631 15.9679 16.5171 16.2419 16.2441C16.5139 15.9731 16.7619 15.6731 16.9759 15.3551L15.3179 14.2361C15.1742 14.4486 15.0105 14.6468 14.8289 14.8281Z'
        fill='#2C2738'
      />
      <path
        d='M15.493 11.986C16.3176 11.986 16.986 11.3176 16.986 10.493C16.986 9.66844 16.3176 9 15.493 9C14.6684 9 14 9.66844 14 10.493C14 11.3176 14.6684 11.986 15.493 11.986Z'
        fill='#2C2738'
      />
      <path
        d='M8.5 12C9.32843 12 10 11.3284 10 10.5C10 9.67157 9.32843 9 8.5 9C7.67157 9 7 9.67157 7 10.5C7 11.3284 7.67157 12 8.5 12Z'
        fill='#2C2738'
      />
    </g>
  </svg>
);

export const Web = () => (
  <svg
    width='17'
    height='16'
    viewBox='0 0 17 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect x='0.944336' width='15.1111' height='16' fill='url(#pattern0)' />
    <defs>
      <pattern
        id='pattern0'
        patternContentUnits='objectBoundingBox'
        width='1'
        height='1'
      ></pattern>
      <image id='image0_2184_5393' width='512' height='512' />
    </defs>
  </svg>
);
