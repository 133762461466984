import { Box, Grid, Text, Flex, Image } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Body } from '../Texts/Texts';
import FileCard from './FileCard';
import FolderCard from './FolderCard';
import { colors } from '../../theme/colors';
import ArrowLeftIcon from '../../assets/svg/arrow-left.svg';

interface ResourcesItemsProps {
  folderName?: string;
  type: string;
  data: any[];
  view?: string;
  refetch?: any;
}

const ResourcesItems = ({
  folderName,
  type,
  data,
  view,
  refetch
}: ResourcesItemsProps) => {
  const Card = type === 'folders' ? FolderCard : FileCard;
  return (
    <Box cursor='pointer'>
      {view === 'folderView' ? (
        <Link to='/resources'>
          <Flex mb='16px'>
            <Image src={ArrowLeftIcon} boxSize='24px' />
            <Text color={colors.deep_blue} fontSize={'16px'}>
              {folderName}
            </Text>
          </Flex>
        </Link>
      ) : (
        <Body color='deep_blue' mb='16px' textTransform='capitalize'>
          {type}
        </Body>
      )}
      <Grid gridTemplateColumns='repeat(3,minmax(0, 1fr))' gap='32px' mb='28px'>
        {data?.length ? (
          data.map((item) => (
            <Card
              refetch={refetch}
              name={item?.fileName || item?.folderName}
              key={item._id}
              id={item._id}
              url={type === 'folders' ? null : item?.fileUrl}
            />
          ))
        ) : view === 'folderView' ? (
          <Text color={colors.deep_blue} fontSize='14px'>
            This folder is empty
          </Text>
        ) : (
          <Text color={colors.deep_blue}>Nothing to show here.</Text>
        )}
      </Grid>
    </Box>
  );
};

export default ResourcesItems;
