import { Box } from '@chakra-ui/react';
import React from 'react';
import { Overline } from '../Texts/Texts';

interface SymptomsTagProps {
  text: string;
  [x: string]: any;
  color?: string;
}

const SymptomsTag = ({ text, color, ...rest }: SymptomsTagProps) => {
  return (
    <Box
      display='inline-block'
      py='4px'
      px='8px'
      bg='white'
      borderRadius='40px'
      border='1px'
      borderColor={color || 'white'}
      {...rest}
    >
      <Overline type='small' color={color || 'gray'}>
        {text}
      </Overline>
    </Box>
  );
};

export default SymptomsTag;
