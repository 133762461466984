import { Box, Flex } from '@chakra-ui/react';
import { colors } from '../../theme/colors';
import { Body, Headline } from '../Texts/Texts';
import { hexToRgbA } from '../../utils/helper';
import { Severity } from '../../interface';
import Card from './Card';

interface ItemProps {
  no: string | number;
  text: string;
  color: string;
  isDownloading?: boolean;
}

const Item = ({ no, text, color, isDownloading }: ItemProps) => {
  return (
    <Box>
      <Headline size='23px' mb='16px' fontWeight={700}>
        {no}
      </Headline>
      <Flex
        alignItems='center'
        h='30'
        px='8px'
        justifyContent='center'
        borderRadius='4px'
        bg={hexToRgbA(color, '0.15')}
        width='auto'
      >
        <Body size='14px' color={color} mb={isDownloading ? '12px' : ''}>
          {text}
        </Body>
      </Flex>
    </Box>
  );
};

const UnassignedSeverityCard = ({
  severity,
  isDownloading
}: {
  severity: Severity;
  isDownloading?: boolean;
}) => {
  const card = (
    <Card
      h='160px'
      mb='16px'
      py='32px'
      bg={isDownloading ? colors.light_green : colors.white}
    >
      <Flex
        h='100%'
        w='100%'
        flexDirection='column'
        justifyContent='space-between'
      >
        <Body size='16px' color='gray'>
          Unassigned Cases by Severity
        </Body>
        <Flex justifyContent='space-between'>
          <Item
            isDownloading={isDownloading}
            no={severity?.severe}
            text='Severe'
            color={colors.error}
          />
          <Item
            no={severity?.moderatelySevere}
            text='Moderately Severe'
            color={colors.warning}
          />
          <Item
            isDownloading={isDownloading}
            no={severity?.moderate}
            text='Moderate'
            color={colors.accent}
          />
          <Item
            isDownloading={isDownloading}
            no={severity?.mild}
            text='Mild'
            color={colors.gray}
          />
        </Flex>
      </Flex>
    </Card>
  );
  const unassignedSeverityCard = severity ? card : null;
  return unassignedSeverityCard;
};

export default UnassignedSeverityCard;
