import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import '@fontsource/inter/100.css';
import '@fontsource/inter/200.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/800.css';
import React from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation
} from 'react-router-dom';
import { AuthContext } from './context/AuthContext';
import AuthProvider from './context/AuthProvider';
import Accounts from './screens/accounts';
import CreatePassword from './screens/authentication/CreatePassword';
import CreateProfile from './screens/authentication/CreateProfile';
import ForgotPassword from './screens/authentication/ForgotPassword';
import SignIn from './screens/authentication/Signin';
import Cases from './screens/Cases';
import Case from './screens/Cases/Case';
import Counsellors from './screens/counsellors';
import Dashboard from './screens/dashboard/Dashboard';
import Resources from './screens/resources';
import Sessions from './screens/sessions';
import appTheme from './theme';

import './App.css';
import Analytics from './screens/Analytics';
import FolderView from './screens/resources/FolderView';
import DashboardProvider from './context/DashboardProvider';
import VerifyResetCode from './screens/authentication/VerifyResetCode';
import VerifyCommunityInvite from './screens/resources/VerifyCommunityInvite';

const theme = extendTheme({
  ...appTheme
});

const Protected = ({ children }: { children: JSX.Element }) => {
  const auth = React.useContext(AuthContext);
  const location = useLocation();

  if (!auth?.authenticated) {
    return (
      <Navigate
        to='/authentication/signin'
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
};

function App() {
  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <DashboardProvider>
          <BrowserRouter>
            <Routes>
              <Route path='/authentication/signin' element={<SignIn />} />
              <Route
                path='/authentication/create-profile'
                element={<CreateProfile />}
              />
              <Route
                path='/authentication/create-password'
                element={<CreatePassword />}
              />
              <Route
                path='/authentication/forgot-password'
                element={<ForgotPassword />}
              />
              <Route
                path='/authentication/verify-reset-code'
                element={<VerifyResetCode />}
              />
              <Route
                path='/dashboard'
                element={
                  <Protected>
                    <Dashboard />
                  </Protected>
                }
              />
              <Route
                path='/analytics'
                element={
                  <Protected>
                    <Analytics />
                  </Protected>
                }
              />
              <Route
                path='/cases'
                element={
                  <Protected>
                    <Cases />
                  </Protected>
                }
              />
              <Route
                path='/cases/:id'
                element={
                  <Protected>
                    <Case />
                  </Protected>
                }
              />
              <Route
                path='/sessions'
                element={
                  <Protected>
                    <Sessions />
                  </Protected>
                }
              />
              <Route
                path='/counsellors'
                element={
                  <Protected>
                    <Counsellors />
                  </Protected>
                }
              />
              <Route
                path='/accounts'
                element={
                  <Protected>
                    <Accounts />
                  </Protected>
                }
              />
              <Route
                path='/resources'
                element={
                  <Protected>
                    <Resources />
                  </Protected>
                }
              />
              <Route
                path='/community/verify-invite'
                element={
                  <Protected>
                    <VerifyCommunityInvite />
                  </Protected>
                }
              />
              <Route
                path='/resources/:id'
                element={
                  <Protected>
                    <FolderView />
                  </Protected>
                }
              />
              <Route path='*' element={<Navigate to='/dashboard' />} />
            </Routes>
          </BrowserRouter>
        </DashboardProvider>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
