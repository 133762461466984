import { Box } from '@chakra-ui/react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { Body } from '../../components/Texts/Texts';
import { colors } from '../../theme/colors';
import Card from './Card';

interface SymptomsChartProps {
  data: any;
}

const SymptomsChart = ({ data }: SymptomsChartProps) => {
  const symptomsChart = (
    <Card h='307px' w='80%' py='32px'>
      <Box width='100%'>
        <Body size='16px' color='gray' mb='12px'>
          Prevalent Symptoms
        </Body>
        <Box bg='deep_blue' borderRadius='12px' margin='auto'>
          <ResponsiveContainer width='100%' height={222}>
            <BarChart
              data={data}
              margin={{
                top: 40,
                right: 40
              }}
            >
              <CartesianGrid
                strokeDasharray='5'
                stroke={colors.light_green}
                vertical={false}
                horizontal={true}
              />
              <XAxis
                dataKey='name'
                strokeWidth={0}
                tick={{ fontSize: 10, fill: colors.light_green }}
              />
              <YAxis
                strokeWidth={0}
                tick={{ fontSize: 10, fill: colors.light_green }}
              />
              <Tooltip
                cursor={{ fill: 'transparent' }}
                labelFormatter={() => ''}
                formatter={(val: any) => [val]}
                contentStyle={{
                  backgroundColor: 'transparent',
                  borderRadius: 8,
                  fontSize: '10px',
                  padding: '1px 6px',
                  color: colors.light_green,
                  borderColor: colors.light_green
                }}
              />
              <Bar
                name='Prevalent Symptoms'
                dataKey='value'
                fill={colors.light_green}
                barSize={8}
                width={8}
                radius={[5, 5, 5, 5]}
              />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    </Card>
  );
  const chart = data.length ? symptomsChart : <span></span>;
  return chart;
};

export default SymptomsChart;
