import { colors } from '../../theme/colors';
import React, { useState, useEffect } from 'react';
import CardHeader from '../Dashboard/CardHeader';
import { Box, Flex, HStack, VStack, Text } from '@chakra-ui/react';
import useRangeChange from '../../hooks/useRangeChange';
import { getFromUrl } from '../../api/Api';
import Card from '../Dashboard/Card';

const AgeRatioChart = () => {
  const [data, setData] = useState<any>({});
  const { range, handleChange } = useRangeChange();
  const [gender, setGender] = useState<string>();
  const [selectedGender, setSelectedGender] = useState('ALL');

  const getAgeRatio = async () => {
    try {
      const result = await getFromUrl(
        gender
          ? `users/age/ratio?range=${range}&&gender=${gender}`
          : `users/age/ratio?range=${range}`
      );
      setData(result);
    } catch (e: any) {
      return;
    }
  };

  useEffect(() => {
    getAgeRatio().catch((err) => console.error(err));
  }, [range, gender]);

  const handleGenderSelect = (gender: string) => {
    setSelectedGender(gender);
    if (gender.toUpperCase() === 'ALL') {
      setGender(undefined);
    } else {
      setGender(gender);
    }
  };

  const genderOptions = [
    { label: 'ALL', color: '#389583' },
    { label: 'MALE', color: '#F2AC57' },
    { label: 'FEMALE', color: '#0880AE' }
  ];

  // Find the maximum value in the data
  const maxValue = Math.max(...Object.values(data as number));

  return (
    <Card mt='20px'>
      <Box>
        <CardHeader title='Age Range' handleChange={handleChange} />
        {Object.keys(data).length ? (
          <Flex mt='20px' width={300} height={300}>
            <VStack align='start' spacing='1px'>
              {genderOptions.map((option) => (
                <HStack
                  key={option.label}
                  spacing='8px'
                  p='8px'
                  borderRadius='4px'
                  bg={
                    selectedGender === option.label ? '#EBF4F8' : 'transparent'
                  }
                  fontSize='13px'
                  cursor='pointer'
                  onClick={() => handleGenderSelect(option.label)}
                >
                  <Box width='9px' height='9px' bg={option.color} />
                  <Text>{option.label}</Text>
                </HStack>
              ))}
            </VStack>
            <Flex flexGrow={1} direction='column' pl='16px' gap='20px'>
              {Object.entries(data).map(([ageRange, value]) => (
                <Flex key={ageRange} align='center' mb='8px'>
                  <Text
                    fontSize='small'
                    fontFamily='Inter'
                    fontWeight='400'
                    minWidth='60px'
                  >
                    {ageRange === '< 18'
                      ? 'BELOW 18'
                      : ageRange === '45 >'
                      ? 'ABOVE 45'
                      : ageRange}
                  </Text>
                  <Flex flexGrow={1} align='center' ml='20px'>
                    <Box
                      bg='#389583'
                      height='8px'
                      borderRadius='4px'
                      width={`${((value as number) / maxValue) * 100}%`}
                    />
                    <Text ml='8px'>
                      {((value as number) * 100).toFixed(0)}%
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </Flex>
        ) : (
          <Flex
            justifyContent='center'
            marginX='auto'
            marginY='40px'
            width='100%'
            color={colors.gray}
          >
            No data available
          </Flex>
        )}
      </Box>
    </Card>
  );
};

export default AgeRatioChart;
