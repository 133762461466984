import {
  Box,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import React from 'react';
import countries from '../../utils/countries.json';
import { getFlag } from '../../utils/helper';
import { Icon } from '../Icons';
import { Overline, Subtitle } from '../Texts/Texts';
import PasswordStrengthBar from 'react-password-strength-bar';

interface TextFieldProps {
  onChange: any;
  value: string;
  name?: string;
  label: string;
  type?: string;
  required?: boolean;
  strengthBar?: boolean;
  bg?: string;
  rightAccessory?: any;
  error?: string;
  fontSize?: any;
  setPasswordScore?: any;
}

export const TextField = ({
  required,
  onChange,
  value,
  name,
  label,
  strengthBar,
  type = 'text',
  bg,
  rightAccessory,
  error,
  fontSize,
  setPasswordScore
}: TextFieldProps) => {
  const [focusedInput, setFocusedInput] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(() => type === 'text');

  const inputFocused = () => {
    setFocusedInput(true);
  };

  const inputBlurred = () => {
    setFocusedInput(false);
  };

  return (
    <Box w='100%' mb='16px'>
      <Flex
        h='64px'
        w='100%'
        borderRadius='12px'
        borderWidth='1px'
        borderColor={
          error ? 'error' : focusedInput || value ? 'accent' : 'shade'
        }
        alignItems='center'
        position='relative'
        px='20px'
        bg={bg || 'transparent'}
      >
        <Text
          position='absolute'
          left='20px'
          fontSize={focusedInput || value ? '10px' : fontSize || '16px'}
          transition='0.25s ease'
          top={focusedInput || value ? '5px' : '20px'}
          color='gray'
          letterSpacing={focusedInput || value ? '1.5px' : '0.25px'}
          textTransform='capitalize'
          zIndex={1}
        >
          {label}
        </Text>
        <Input
          border='none'
          outline='none'
          name={name}
          boxShadow='0'
          _focus={{
            outline: 'none',
            boxShadow: 'none'
          }}
          onFocus={inputFocused}
          onBlur={inputBlurred}
          onChange={(e) => onChange(e.target.value)}
          value={value}
          color='black'
          p={0}
          type={showPassword ? 'text' : 'password'}
          bg='transparent'
          zIndex={2}
          h='20px'
          required={required}
        />
        {rightAccessory}
        {type === 'password' && (
          <Box
            as='span'
            cursor='pointer'
            onClick={() => setShowPassword(!showPassword)}
          >
            <Icon name={showPassword ? 'Hide' : 'Show'} />
          </Box>
        )}
      </Flex>
      {type === 'password' && strengthBar && value && (
        <PasswordStrengthBar
          password={value}
          onChangeScore={(score) => {
            setPasswordScore(score);
          }}
          style={{
            height: '10px'
          }}
        />
      )}
      {type === 'password' && strengthBar && !value && (
        <Overline mt='4px' type='small'>
          At least 8 characters
        </Overline>
      )}
    </Box>
  );
};

interface SearchInputProps {
  [x: string]: any;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SearchInput = ({ ...rest }: SearchInputProps) => {
  return (
    <InputGroup {...rest}>
      <InputLeftElement pointerEvents='none' />
      <Input
        type='text'
        placeholder='Type here...'
        fontSize='12px'
        minW='244px'
        h='40px'
        borderRadius='15px'
        borderWidth='0.5px'
        borderColor='gray_200'
        bg='white'
      />
    </InputGroup>
  );
};

interface PhoneInputProps extends TextFieldProps {
  handleCountry: (arg: any) => void;
  countryCode: string;
}

export const PhoneInput = ({
  label,
  onChange,
  value,
  handleCountry,
  countryCode,
  bg
}: PhoneInputProps) => {
  const [countriesList] = React.useState(countries);
  const [countryData, setCountryData] = React.useState<any>({});
  const [showList, setShowList] = React.useState(false);

  React.useEffect(() => {
    if (countryCode) {
      setCountry(countryCode);
    }
  }, [countryCode]);

  const setCountry = (code: string) => {
    handleCountry(code);
    const country = countriesList.find((item) => item.dialCode === code);
    setCountryData(country);
  };

  return (
    <Flex>
      <Menu>
        <MenuButton as={Box} onClick={() => setShowList(true)}>
          <Flex
            h='64px'
            w='124px'
            borderRadius='12px'
            borderWidth='1px'
            borderColor='shade'
            alignItems='center'
            position='relative'
            pl='15px'
            pr='10px'
            bg={bg || 'transparent'}
            mr='8px'
            cursor='pointer'
          >
            <Image
              src={getFlag(countryData.isoCode)}
              w='20px'
              h='16px'
              alt=''
              mr='5px'
            />
            <Subtitle mr='auto'>{countryData.dialCode}</Subtitle>
            <ChevronDownIcon />
          </Flex>
        </MenuButton>
        <MenuList bg='white' zIndex={5}>
          <Box maxH='350px' overflowY='scroll'>
            {showList &&
              countriesList.map((country) => {
                return (
                  <MenuItem
                    minH='48px'
                    onClick={() => {
                      setCountry(country.dialCode);
                      setShowList(false);
                    }}
                    key={country.name + country.dialCode}
                  >
                    <Image
                      w='20px'
                      h='16px'
                      alt=''
                      src={getFlag(country.isoCode)}
                      mr='12px'
                    />
                    <Subtitle>
                      {country.name}({country.dialCode})
                    </Subtitle>
                  </MenuItem>
                );
              })}
          </Box>
        </MenuList>
      </Menu>
      <TextField label={label} onChange={onChange} value={value} bg={bg} />
    </Flex>
  );
};
