import { Box, useToast } from '@chakra-ui/react';
import React from 'react';
import { TextField } from '../../components/Inputs/TextField';
import { Headline } from '../../components/Texts/Texts';
import { PrimaryButton, TextButton } from '../../components/Buttons/Buttons';
import { serverRequest } from '../../api/Api';
import { noErrors } from '../../utils/helper';

const CreateCommunity = ({
  onClose,
  getCommunities,
  setCommunityData
}: {
  onClose: () => void;
  getCommunities: any;
  setCommunityData: any;
  getProfile: any;
}) => {
  const toast = useToast();

  const [createIsLoading, setCreateIsLoading] = React.useState(false);
  const [buttonDisabled, setbuttonDisabled] = React.useState(true);

  const [community, setCommunity] = React.useState({
    name: '',
    description: ''
  });

  React.useEffect(() => {
    const emptyFields = noErrors(community);
    setbuttonDisabled(emptyFields);
  }, [community]);

  const handleCommunity = (key: string, value: string) => {
    setCommunity({
      ...community,
      [key]: value
    });
  };

  const createCommunity = (e: any) => {
    e.preventDefault();
    try {
      setCreateIsLoading(true);
      serverRequest({
        endpoint: 'community/room',
        body: community,
        method: 'POST'
      }).then((res) => {
        setCreateIsLoading(false);
        onClose();
        toast({
          position: 'top',
          title: 'Success',
          description: `You've successfully created community ${community.name}`,
          status: 'success',
          duration: 9000,
          isClosable: true
        });
        setCommunity({
          name: '',
          description: ''
        });
        setCommunityData({ ...res, people: 0 });
        getCommunities();
      });
    } catch (e: any) {
      setCreateIsLoading(false);
    }
  };

  return (
    <Box px='86px' py='56px' bg='gray_50' borderRadius='8px'>
      <Headline color='gray' size='19px' textAlign='center' mb='24px'>
        Enter Name and Description of Community
      </Headline>
      <form onSubmit={createCommunity}>
        <TextField
          label='Enter Name of Community'
          onChange={(value: string) => handleCommunity('name', value)}
          bg='white'
          value={community.name}
          required={true}
        />
        <TextField
          label='Enter Community Description'
          onChange={(value: string) => handleCommunity('description', value)}
          bg='white'
          value={community.description}
          required={true}
        />
        <PrimaryButton
          label='create'
          mt='24px'
          disabled={buttonDisabled}
          isLoading={createIsLoading}
          type={'submit'}
        />
      </form>

      <TextButton label='cancel' onClick={onClose} mt='24px' />
    </Box>
  );
};

export default CreateCommunity;
