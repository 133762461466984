import { createContext } from 'react';

export interface AuthContextInterface {
  authenticated: string;
  signIn: (token: string, user: string, callback: any) => void;
  signOut: (callback?: any) => void;
  //   loading: boolean;
}

export const AuthContext = createContext<AuthContextInterface>(
  {} as AuthContextInterface
);
