import { TextField } from '../../components/Inputs/TextField';
import { PrimaryButton } from '../../components/Buttons/Buttons';
import AuthLayout from '../../layouts/auth/AuthLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { serverRequest } from '../../api/Api';
import { Button, Text, useToast } from '@chakra-ui/react';
import { colors } from '../../theme/colors';

const CreatePassword = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordScore, setPasswordScore] = useState(0);
  const [error, setError] = useState('');

  const skip = location?.state?.skip;
  const code = location?.state?.code;
  return (
    <AuthLayout
      title='Create a Strong Password'
      subtitle='Create a new password or skip to continue using the current password'
    >
      {!skip && !code ? (
        <TextField
          label='old password'
          onChange={setOldPassword}
          value={oldPassword}
          name='old-password'
          type='password'
          setPasswordScore={setPasswordScore}
        />
      ) : null}
      <TextField
        label='new password'
        onChange={setPassword}
        value={password}
        name='confirm-password'
        type='password'
        strengthBar
        setPasswordScore={setPasswordScore}
      />
      <TextField
        label='confirm'
        onChange={setConfirmPassword}
        value={confirmPassword}
        name='confirm-password'
        type='password'
        strengthBar
        setPasswordScore={setPasswordScore}
      />
      <Text color={colors.error}>{error}</Text>
      <PrimaryButton
        label='Change password'
        mt='24px'
        onClick={() => {
          if (password === confirmPassword && passwordScore >= 2) {
            const authDetails = location?.state?.authDetails;
            const body = {
              oldPassword,
              password,
              confirmPassword
            };

            if (code) {
              const body = {
                code,
                password,
                confirmPassword
              };
              serverRequest({
                endpoint: `users/resetPassword/${location?.state?.email}`,
                body,
                method: 'PUT'
              }).then((res) => {
                if (res.statusCode === 400) {
                  setError(res.message);
                  return;
                }
                if (res.message === 'Password changed successful') {
                  toast({
                    position: 'top',
                    title: 'Success',
                    description: res.message,
                    status: 'success',
                    duration: 3000,
                    isClosable: true
                  });
                  navigate('/authentication/signin');
                } else {
                  toast({
                    position: 'top',
                    title: 'Error',
                    description: res.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true
                  });
                }
              });
              return;
            }
            if (skip) {
              body.oldPassword = authDetails.password;
            }
            let endpoint = `users/changePassword/${authDetails?.username}`;
            if (!skip) {
              const cachedUser = localStorage.getItem('user');
              if (cachedUser) {
                const user = JSON.parse(cachedUser);
                endpoint = `users/changePassword/${user.email}`;
              }
            }
            serverRequest({
              endpoint,
              body,
              method: 'PUT'
            }).then((res) => {
              if (res.statusCode === 400) {
                setError(res.message);
                return;
              }
              if (res.message === 'Password reset successfully') {
                toast({
                  position: 'top',
                  title: 'Success',
                  description: res.message,
                  status: 'success',
                  duration: 3000,
                  isClosable: true
                });
                if (skip) navigate('/authentication/create-profile');
                else navigate('/authentication/accounts');
              }
            });
          }
          if (password !== confirmPassword) setError('Passwords do not match.');
          if (passwordScore < 2) setError('Password not strong enough.');
        }}
      />
      {skip && (
        <Button
          mt='24px'
          mx='auto'
          display='block'
          fontWeight='normal'
          fontSize='0.9rem'
          color={colors.deep_blue}
          onClick={() => {
            navigate('/authentication/create-profile');
          }}
        >
          SKIP
        </Button>
      )}
    </AuthLayout>
  );
};

export default CreatePassword;
