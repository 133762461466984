import React from 'react';
import {
  PlacementWithLogical,
  Popover,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  useDisclosure
} from '@chakra-ui/react';

interface PopoverMenuProps {
  placement?: PlacementWithLogical;
  trigger: any;
  children: any;
  [x: string]: any;
}

const PopoverMenu = ({
  placement,
  trigger,
  children,
  ...rest
}: PopoverMenuProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  return (
    <Popover
      placement={placement}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <PopoverTrigger>{trigger}</PopoverTrigger>
      <PopoverContent
        border='none'
        _focus={{ outline: 'none' }}
        boxShadow='0px 8px 16px rgba(8, 35, 48, 0.2)'
        {...rest}
      >
        <PopoverCloseButton
          bg='shade'
          boxSize='26px'
          borderRadius='13px'
          color='muted'
        />
        {children(onClose)}
      </PopoverContent>
    </Popover>
  );
};

export default PopoverMenu;
