import {
  Box,
  Flex,
  HStack,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { getFromUrl, serverRequest } from '../../api/Api';
import {
  IconButton,
  PrimaryButton,
  TextButton
} from '../../components/Buttons/Buttons';
import CounsellorsTable from '../../components/Cases/CounsellorsTable';
import DetailsView from '../../components/Counsellors/DetailsView';
import IconBox from '../../components/IconBox';
import { Icon } from '../../components/Icons';
import { SearchInput, TextField } from '../../components/Inputs/TextField';
import ModalView from '../../components/Modal';
import { Headline } from '../../components/Texts/Texts';
import DashboardLayout from '../../layouts/dashboard/DashboardLayout';
import { colors } from '../../theme/colors';
import { hexToRgbA } from '../../utils/helper';

const Counsellors = () => {
  const [searchKeyword, setSearchKeyword] = useState('');
  const {
    isOpen: showDetails,
    onOpen: openDetails,
    onClose: closeDetails
  } = useDisclosure();

  const {
    isOpen: showInvite,
    onOpen: openInvite,
    onClose: closeInvite
  } = useDisclosure();

  const {
    isOpen: showPrompt,
    onOpen: openPrompt,
    onClose: closePrompt
  } = useDisclosure();

  const toast = useToast();

  const [counsellors, setCounsellors] = useState([]);
  const [counsellorDetails, setCounsellorDetails] = useState(null);
  const [deleteId, setDeleteId] = useState('');
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');
  const [loadingInvite, setLoadingInvite] = useState(false);
  const [counsellorsCount, setCounsellorsCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchResults, setSearchResults] = useState([]);
  const PAGE_SIZE = 10;
  const [timeoutId, setTimeoutId] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const viewCounsellor = (counsellor: any) => {
    setCounsellorDetails(counsellor);
    openDetails();
  };

  useEffect(() => {
    getCounsellorsCount().catch((err) => console.error(err));
  }, []);
  useEffect(() => {
    getCounsellors().catch((err) => console.error(err));
  }, [currentPage]);

  const getCounsellors = async () => {
    try {
      const data = await getFromUrl(
        `users/counsellors?type=COUNSELLOR&size=${PAGE_SIZE}&page=${currentPage}`
      );
      if (data !== undefined) setCounsellors(data);
    } catch (e: any) {
      return;
    }
  };
  const getCounsellorsCount = async () => {
    try {
      const { count } = await getFromUrl('users/overview');
      setCounsellorsCount(count?.counsellors);
    } catch (e: any) {
      return;
    }
  };

  const deleteCounsellor = async () => {
    setLoadingDelete(true);
    try {
      serverRequest({
        endpoint: `users/${deleteId}`,
        body: null,
        method: 'DELETE'
      }).then((res) => {
        if (res.status === 400) {
          toast({
            position: 'top',
            title: 'Error',
            description: 'User must be a counsellor',
            status: 'error',
            duration: 3000,
            isClosable: true
          });
        } else {
          toast({
            position: 'top',
            title: 'Success',
            description: `Counsellor deleted successfully`,
            status: 'success',
            duration: 3000,
            isClosable: true
          });
          getCounsellors().catch(() => {
            return;
          });
        }
      });
      setLoadingDelete(false);
      closeDeletePrompt();
    } catch (e: any) {
      setLoadingDelete(false);
    }
  };

  const openDeletePrompt = (counsellor: any) => {
    openPrompt();
    setDeleteId(counsellor._id);
  };

  const closeDeletePrompt = () => {
    closePrompt();
    setDeleteId('');
  };

  const inviteCounsellor = async () => {
    if (inviteEmail) {
      setLoadingInvite(true);
      const body = {
        email: inviteEmail,
        role: 'COUNSELLOR'
      };
      try {
        const response = await serverRequest({
          endpoint: 'users/create',
          body,
          method: 'POST'
        });
        if (response.status === 400) {
          toast({
            position: 'top',
            title: 'Error',
            description: 'Counsellor already exists',
            status: 'error',
            duration: 3000,
            isClosable: true
          });
        } else {
          getCounsellors().catch((err) => console.error(err));
          toast({
            position: 'top',
            title: 'Success',
            description: `You've sent an invite to ${inviteEmail}`,
            status: 'success',
            duration: 3000,
            isClosable: true
          });
        }
        setLoadingInvite(false);
        setInviteEmail('');
        closeInvite();
        return response;
      } catch (e: any) {
        console.error(e);
        setLoadingInvite(false);
      }
    }
  };

  const searchCounsellorsList = async () => {
    if (timeoutId) clearTimeout(timeoutId);
    const searchTimeoutId = setTimeout(async () => {
      try {
        const endpoint = `users/counsellors/search?search=${searchKeyword}`;
        const searchResults = await getFromUrl(endpoint);
        setSearchResults(searchResults);
      } catch (e: any) {
        console.error(e.message);
      }
    }, 1000);
    setTimeoutId(searchTimeoutId);
  };
  useEffect(() => {
    if (searchKeyword.length) {
      searchCounsellorsList().catch((err) => console.error(err));
    }
  }, [searchKeyword]);

  return (
    <DashboardLayout pageTitle='Counsellors'>
      <Box pr='60px' pb='54px'>
        <Flex justifyContent='space-between' alignItems='center' mb='30px'>
          <IconButton
            label='Add Counsellor'
            icon={<Icon name='Plus' color={colors.white} filled />}
            onClick={openInvite}
          />
          <SearchInput
            w='244px'
            value={searchKeyword}
            onChange={({ target: { value } }) => setSearchKeyword(value)}
          />
        </Flex>

        {counsellorsCount && counsellors.length ? (
          <CounsellorsTable
            onPageChange={onPageChange}
            currentPage={currentPage}
            counsellors={searchKeyword.length ? searchResults : counsellors}
            searchText={searchKeyword}
            counsellorsCount={counsellorsCount}
            actionItem={({ counsellorData }: { counsellorData: any }) => (
              <HStack spacing='20px'>
                <IconBox
                  icon='Show'
                  iconColor={colors.dark_green}
                  bg={hexToRgbA(colors.dark_green, '0.15')}
                  iconSize={16}
                  boxSize='36px'
                  round
                  cursor='pointer'
                  onClick={() => viewCounsellor(counsellorData)}
                />

                <IconBox
                  icon='Close-Square'
                  iconColor={colors.error}
                  bg={hexToRgbA(colors.error, '0.15')}
                  iconSize={16}
                  boxSize='36px'
                  round
                  cursor='pointer'
                  onClick={() => openDeletePrompt(counsellorData)}
                />
              </HStack>
            )}
          />
        ) : null}
        {
          <Text mx='auto' w='max-content' color={colors.deep_blue} mt='80px'>
            {searchKeyword.length && !searchResults.length
              ? 'No data found'
              : null}
          </Text>
        }
        <ModalView
          isOpen={showDetails}
          onClose={closeDetails}
          bg='gray_50'
          maxW='600px'
        >
          <DetailsView details={counsellorDetails} />
        </ModalView>
        <ModalView
          isOpen={showInvite}
          onClose={closeInvite}
          bg='gray_50'
          maxW='600px'
          noCloseBtn
        >
          <Box bg='gray_50' px='86px' py='53px' borderRadius='8px'>
            <Headline size='19px' color='gray' mb='28px'>
              An invite will be sent to the Counsellor’s Email
            </Headline>
            <TextField
              label='Type counsellor’s email'
              value={inviteEmail}
              onChange={setInviteEmail}
              bg='white'
            />
            <PrimaryButton
              label='Send Invite'
              onClick={inviteCounsellor}
              isLoading={loadingInvite}
            />
            <TextButton label='cancel' mt='24px' onClick={closeInvite} />
          </Box>
        </ModalView>
        <ModalView
          isOpen={showPrompt}
          onClose={closeDeletePrompt}
          bg='gray_50'
          maxW='454px'
          noCloseBtn
        >
          <Box bg='gray_50' px='55px' py='75px' borderRadius='8px'>
            <Headline size='19px' color='gray' mb='28px' textAlign='center'>
              You are about to permanently remove this counsellor
            </Headline>
            <PrimaryButton
              label='Proceed'
              background='error'
              onClick={deleteCounsellor}
              isLoading={loadingDelete}
            />
            <TextButton label='cancel' mt='24px' onClick={closeDeletePrompt} />
          </Box>
        </ModalView>
      </Box>
    </DashboardLayout>
  );
};

export default Counsellors;
