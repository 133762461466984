import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { colors } from '../theme/colors';
import { Body } from './Texts/Texts';

interface TabsProps {
  tabs: string[];
  activeTab: string;
  tabAction: any;
}

export const Tabs = ({ tabs, activeTab, tabAction }: TabsProps) => {
  return (
    <Flex>
      {tabs.map((tab, index) => {
        const isActive = tab === activeTab;
        const isLast = index === tabs.length - 1;
        return (
          <Box
            onClick={() => tabAction(tab)}
            pb='4px'
            borderBottom={`2px solid ${
              isActive ? colors.deep_blue : colors.gray_50
            }`}
            mr={isLast ? '0px' : '32px'}
            cursor='pointer'
            key={tab + index}
          >
            <Body
              size='14px'
              fontWeight={700}
              textTransform='capitalize'
              color={isActive ? 'deep_blue' : 'gray'}
            >
              {tab}
            </Body>
          </Box>
        );
      })}
    </Flex>
  );
};

interface TabsProps {
  tabs: string[];
  activeTab: string;
  tabAction: any;
  centered?: boolean;
}

export const BlockTabs = ({
  tabs,
  activeTab,
  tabAction,
  centered
}: TabsProps) => {
  return (
    <Flex justifyContent={centered ? 'center' : 'flex-start'}>
      {tabs.map((tab, index) => {
        const isActive = tab === activeTab;
        const isLast = index === tabs.length - 1;
        return (
          <Box
            onClick={() => tabAction(tab)}
            py='4px'
            px='8px'
            border={`1px solid ${isActive ? colors.dark_green : colors.tint}`}
            bg={isActive ? 'dark_green' : 'white'}
            borderRadius='4px'
            mr={isLast ? '0px' : '24px'}
            cursor='pointer'
            key={tab + index}
          >
            <Body
              size='14px'
              fontWeight={500}
              textTransform='capitalize'
              color={isActive ? 'white' : 'gray'}
            >
              {tab}
            </Body>
          </Box>
        );
      })}
    </Flex>
  );
};

export default Tabs;
