import { useState } from 'react';

const useRangeChange = () => {
  const [range, setRange] = useState('TODAY');
  const handleChange = (e: any) => {
    setRange(e.target.value);
  };
  return {
    range,
    handleChange
  };
};

export default useRangeChange;
