import { Flex } from '@chakra-ui/react';
import React from 'react';
import { colors } from '../../theme/colors';
import { hexToRgbA } from '../../utils/helper';
import IconBox from '../IconBox';
import { Body, Headline } from '../Texts/Texts';
import Card from './Card';

const TotalUnassignedCard = ({ no }: { no: string | number }) => {
  return (
    <Card h='207px' mb='16px'>
      <Flex
        h='100%'
        w='100%'
        flexDirection='column'
        justifyContent='space-between'
        py='24px'
      >
        <Flex justifyContent='space-between' alignItems='center'>
          <Headline size='58px' color='black' fontWeight={300}>
            {no}
          </Headline>
          <IconBox
            boxSize='64px'
            bg={hexToRgbA(colors.blue_1, '0.15')}
            icon='Activity'
            iconColor={colors.deep_blue}
            iconSize={34}
            badgeColor={
              no < 50 ? colors.dark_green : no <= 70 ? colors.warning : 'red'
            }
            badgeSize={20}
            badge
          />
        </Flex>
        <Body size='16px' color='gray'>
          {no < 50
            ? 'Cases are currently fewer than 50, let’s keep it going 👍'
            : no <= 70
            ? 'There are currently more than 50 cases, let’s double up❗'
            : 'Cases have reached critical levels, we need all hands on deck '}
        </Body>
      </Flex>
    </Card>
  );
};

export default TotalUnassignedCard;
