import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { Icon } from './Icons';

interface IconBoxProps {
  bg: string;
  boxSize: string;
  icon: string;
  iconSize: number;
  iconColor: string;
  [x: string]: any;
  round?: boolean;
  badge?: boolean;
  badgeColor?: string;
  badgeSize?: number;
  isDownloading?: boolean;
}

const IconBox = ({
  bg,
  boxSize,
  icon,
  iconSize,
  iconColor,
  badge,
  badgeColor,
  badgeSize = 10,
  round,
  isDownloading,
  ...rest
}: IconBoxProps) => {
  const badgePosition = () => {
    const containerSize = parseInt(boxSize);
    const offset = (containerSize - iconSize) / 2;

    return `${offset}px`;
  };
  return (
    <Flex
      w='100%'
      justifyContent='center'
      alignItems='center'
      bg={bg}
      boxSize={boxSize}
      borderRadius={round ? `${parseInt(boxSize) / 2}px` : '12px'}
      position='relative'
      {...rest}
      pb={isDownloading ? '20px' : ''}
    >
      {badge && (
        <Box
          boxSize={`${badgeSize}px`}
          borderRadius={`${badgeSize / 2}px`}
          bg={badgeColor}
          position='absolute'
          top={badgePosition()}
          right={badgePosition()}
          zIndex={2}
        />
      )}
      <Icon name={icon} filled color={iconColor} size={iconSize} />
    </Flex>
  );
};

export default IconBox;
