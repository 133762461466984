import { Flex, Select, Text } from '@chakra-ui/react';
const CardHeader = ({
  title,
  handleChange
}: {
  title: string;
  handleChange?: any;
}) => {
  return (
    <Flex justifyContent='space-between' alignItems='center' width='100%'>
      <Text color='#756F86'>{title}</Text>
      <Select
        style={{
          border: 'none',
          outline: 'none',
          width: 'max-content',
          textAlign: 'right'
        }}
        color='#05396B'
        cursor='pointer'
        width='max-content'
        outline='none'
        onChange={handleChange}
      >
        <option value='TODAY'>Today</option>
        <option value='YESTERDAY'>Yesterday</option>
        <option value='ONE_WEEK'>1 Week</option>
        <option value='ONE_MONTH'>1 Month</option>
        <option value='LAST_6_MONTHS'>Last 6 Months</option>
        <option value='LAST_1_YEAR'>Last 1 Year</option>
        <option value='ALL_TIME'>All Time</option>
      </Select>
    </Flex>
  );
};
export default CardHeader;
