import { Box, Flex } from '@chakra-ui/react';
import { hexToRgbA } from '../../utils/helper';
import IconBox from '../IconBox';
import { Body, Headline } from '../Texts/Texts';

interface DefaultCardProps {
  no: string | number;
  text: string;
  icon: string;
  color: string;
  bold?: boolean;
  isDownloading?: boolean;
}

const DefaultCard = ({
  no,
  text,
  icon,
  color,
  bold,
  isDownloading
}: DefaultCardProps) => {
  return (
    <Flex h='100%' w='100%' flexDirection='column' justifyContent='center'>
      <Flex justifyContent='space-between' alignItems='center'>
        <Box>
          <Headline
            size={bold ? '23px' : '33px'}
            color='black'
            fontWeight={bold ? 700 : 400}
          >
            {no}
          </Headline>
          <Body size='16px' color='gray'>
            {text}
          </Body>
        </Box>
        <IconBox
          isDownloading={isDownloading}
          boxSize='50px'
          bg={hexToRgbA(color, '0.15')}
          icon={icon}
          iconColor={color}
          iconSize={22}
        />
      </Flex>
    </Flex>
  );
};

export default DefaultCard;
