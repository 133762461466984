import { useEffect, useRef, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { base_url } from '../api/Api';

export const useSessionSocket = (namespace: string) => {
  const ref = useRef<Socket>();
  const [connected, setConnected] = useState(false);
  const [newMessage, setNewMessage] = useState(null);

  const send = (eventName: string, data: any) => {
    console.log(eventName, data);
    ref.current?.emit(eventName, data);
  };

  const receive = (client: string, fn: (arg: any) => void) => {
    if (!ref.current?.hasListeners(client)) {
      // Check if listener already exists
      console.log('The client', client);
      ref.current?.on(client, fn);
    }
  };

  useEffect(() => {
    if (!ref.current) {
      const setup = async () => {
        const token = localStorage.getItem('access_token');
        const socket = io(base_url + '/sessions', {
          path: '/sockets/',
          transports: ['websocket']
        });

        socket.on('disconnect', () => {
          console.log('disconnected');
          setConnected(false);
        });

        socket.on('connect', () => {
          console.log(`${namespace} socket connected - ${socket.id}`);
          setConnected(true);
        });

        ref.current = socket; // Ensure socket is only set once
      };

      setup().catch((e) => {
        console.log('Socket setup error', (e as Error).message);
        return;
      });
    }

    return () => {
      ref.current?.disconnect(); // Disconnect socket on unmount or cleanup
    };
  }, [namespace]);

  return {
    send,
    receive,
    connected,
    newMessage,
    clientSocketRef: ref
  };
};
