import { Box, Text } from '@chakra-ui/react';

interface TextProps {
  children: any;
  size?: string;
  color?: string;
  fontWeight?: number;

  [x: string]: any;
}

export const Headline = ({
  children,
  size,
  color,
  fontWeight,
  ...rest
}: TextProps) => (
  <Text
    fontSize={size ? size : '23px'}
    color={color ? color : 'black'}
    lineHeight='120%'
    letterSpacing='0.2px'
    fontWeight={fontWeight ? fontWeight : 500}
    {...rest}
  >
    {children}
  </Text>
);

export const Body = ({
  children,
  size,
  color,
  fontWeight,
  ...rest
}: TextProps) => (
  <Box
    fontSize={size || '14px'}
    color={color ? color : 'black'}
    lineHeight='140%'
    letterSpacing='0.25px'
    w='100%'
    overflowWrap='break-word'
    fontWeight={fontWeight ? fontWeight : 400}
    {...rest}
  >
    {children}
  </Box>
);

export const Subtitle = ({
  children,
  size,
  color,
  fontWeight,
  ...rest
}: TextProps) => (
  <Text
    fontSize={size ? size : '16px'}
    color={color ? color : 'black'}
    lineHeight='140%'
    letterSpacing='0.1px'
    width='100%'
    fontWeight={fontWeight ? fontWeight : 400}
    fontFamily='Inter'
    {...rest}
  >
    {children}
  </Text>
);

export const Caption = ({
  children,
  size,
  color,
  fontWeight,
  ...rest
}: TextProps) => (
  <Text
    fontSize={size ? size : '12px'}
    color={color ? color : 'black'}
    lineHeight='130%'
    letterSpacing='0.4px'
    fontWeight={fontWeight ? fontWeight : 400}
    {...rest}
  >
    {children}
  </Text>
);

interface OverlineProps extends TextProps {
  type?: string;
}

export const Overline = ({
  children,
  size,
  color,
  fontWeight,
  type,
  ...rest
}: OverlineProps) => (
  <Text
    fontSize={size ? size : '10px'}
    color={color ? color : 'black'}
    lineHeight='160%'
    letterSpacing='1.5px'
    fontWeight={fontWeight ? fontWeight : 400}
    textTransform={type === 'small' ? 'capitalize' : 'uppercase'}
    {...rest}
  >
    {children}
  </Text>
);
