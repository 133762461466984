import { Box, Text } from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';
import { INotification } from 'types';
import NotificationItem from './NotificationItem';

interface INotificationGroupProps {
  notifications: Array<INotification>;
  date: string | any;
}

const NotificationGroup: React.FC<INotificationGroupProps> = ({
  notifications,
  date
}) => {
  moment.updateLocale('en', {
    calendar: {
      lastDay: '[Yesterday]',
      sameDay: '[Today]',
      lastWeek: '[Last]',
      sameElse: 'L'
    }
  });
  return (
    <Box my='16px'>
      <Text maxW='90%' mx='auto' my='8px' color='#05396B'>
        {moment(date)?.calendar()}
      </Text>
      {notifications?.map((item: INotification) => (
        <NotificationItem key={item._id} date={item.createdAt}>
          {item.message}
        </NotificationItem>
      ))}
    </Box>
  );
};

export default NotificationGroup;
