/* eslint-disable react/jsx-handler-names */
import React from 'react';
import { Box } from '@chakra-ui/react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceLine
} from 'recharts';
import { colors } from '../../../theme/colors';

const NationalityRatioChart = ({ data }: { data: any }) => {
  return (
    <Box width='100%'>
      <Box bg='deep_blue' borderRadius='12px' margin='auto'>
        <ResponsiveContainer width='100%' height={350}>
          <BarChart
            layout='vertical'
            data={data}
            margin={{
              left: 0,
              top: 40,
              right: 40,
              bottom: 40
            }}
          >
            <CartesianGrid
              strokeDasharray='5'
              stroke={colors.light_green}
              vertical={true}
              horizontal={false}
            />

            <XAxis
              type='number'
              domain={[0, 100]}
              unit='%'
              stroke={colors.light_green}
              axisLine={false}
            />
            <ReferenceLine y={0} stroke='#000000' />
            <YAxis
              type='category'
              width={110}
              dataKey='name'
              stroke={colors.light_green}
              axisLine={false}
              interval={0}
            />
            <Tooltip
              cursor={{ fill: 'transparent' }}
              labelFormatter={() => ''}
              formatter={(val: any) => [`${val}%`]}
              contentStyle={{
                backgroundColor: 'transparent',
                borderRadius: 8,
                fontSize: '10px',
                padding: '1px 6px',
                color: colors.light_green,
                borderColor: colors.light_green
              }}
            />
            <Bar
              name='Nationality Ratio'
              dataKey='value'
              fill={colors.light_green}
              barSize={8}
              width={8}
              radius={[5, 5, 5, 5]}
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default NationalityRatioChart;
