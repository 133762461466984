import { Flex, Image, Text } from '@chakra-ui/react';
import EmptyDrawer from '../../assets/svg/empty-drawer- 1.png';
const EmptyCases = () => {
  return (
    <Flex pt={['140px']} alignItems='center' flexDirection='column'>
      <Image
        src={EmptyDrawer}
        objectFit='contain'
        width='117px'
        height='117px'
        marginRight={200}
      />
      <Text
        fontFamily='Inter, sans-serif'
        opacity={0.6}
        fontWeight={700}
        color='#756F86'
        marginRight={200}
      >
        No cases yet
      </Text>
    </Flex>
  );
};
export default EmptyCases;
