import { Box } from '@chakra-ui/react';
import { colors } from '../../theme/colors';
import { Caption } from '../Texts/Texts';

interface SeverityTagProps {
  type: string;
  [x: string]: any;
}

const SeverityTag = ({ type, ...rest }: SeverityTagProps) => {
  const tagData = () => {
    switch (type.toLowerCase()) {
      case 'mild':
        return {
          bg: colors.gray,
          label: 'Mild'
        };
      case 'moderate':
        return {
          bg: colors.accent,
          label: 'Moderate'
        };
      case 'moderately_severe':
        return {
          bg: colors.warning,
          label: 'Moderately Severe'
        };
      case 'severe':
        return {
          bg: colors.error,
          label: 'Severe'
        };
      default:
        return {
          bg: colors.gray,
          label: 'Mild'
        };
    }
  };
  return (
    <Box
      display='inline-flex'
      bg={tagData().bg}
      px='8px'
      py='4px'
      borderRadius='4px'
      {...rest}
    >
      <Caption color='white'>{tagData().label}</Caption>
    </Box>
  );
};

export default SeverityTag;
