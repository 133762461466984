export const colors = {
  //primary colors
  deep_blue: '#05396B',
  dark_green: '#389583',
  default_green: '#5CDB94',
  green: {
    500: '#38A169'
  },
  light_green: '#EDF5E0',
  //system colors
  accent: '#0880AE',
  warning: '#F2AC57',
  error: '#FF7171',
  black: '#2C2738',
  gray: '#756F86',
  muted: '#7C9CBF',
  tint: '#EBF4F8',
  gray_50: '#F9F9F9',
  gray_200: '#E2E8F0',
  white: '#FFFFFF',
  input_gray: '#DBE2EA',
  shade: '#DBE2EA',
  bg: '#F3F3F3',
  blue_1: '#2F80ED',
  chat_gray: '#F3F3F3',
  chat_user: '#A1A1BC',
  border: '#E8E6EA'
};
