import { serverRequest } from 'api/Api';
import { useCallback, useState } from 'react';

const useServerRequest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<object | unknown>(null);
  const [data, setData] = useState(null);

  const sendRequest = useCallback(
    async (
      endPoint: string,
      {
        body,
        method
      }: {
        body: any;
        method: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE';
      } = { body: undefined, method: 'GET' }
    ) => {
      try {
        setIsLoading(true);
        const response = await serverRequest({
          endpoint: endPoint,
          body,
          method
        });
        setData(response);
      } catch (err: any) {
        setError(err?.message);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );
  return {
    data,
    error,
    isLoading,
    sendRequest
  };
};

export default useServerRequest;
