import React, { useState, useEffect, useRef } from 'react';
import AuthLayout from '../../layouts/auth/AuthLayout';
import { serverRequest } from '../../api/Api';
import { useLocation, useNavigate } from 'react-router-dom';
import { Flex, Text, Input, Button, useToast } from '@chakra-ui/react';
import { colors } from '../../theme/colors';

const VerifyResetCode = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const location = useLocation();
  const [counter, setCounter] = useState(59);
  const [inputValue1, setInputValue1] = useState(0);
  const [inputValue2, setInputValue2] = useState(0);
  const [inputValue3, setInputValue3] = useState(0);
  const [inputValue4, setInputValue4] = useState(0);
  const [updateFlag, setUpdateFlag] = useState([false, false, false, false]);
  const counterRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const formatCounter = () => {
    if (counter < 10) return `00:0${counter}`;
    else return `00:${counter}`;
  };
  useEffect(() => {
    if (!updateFlag.includes(false)) {
      if (counterRef.current) clearInterval(counterRef.current);
      setTimeout(() => {
        navigate('/authentication/create-password', {
          state: {
            skip: false,
            code: `${inputValue1}${inputValue2}${inputValue3}${inputValue4}`,
            email: location.state.email
          }
        });
      }, 3000);
    }
  }, [
    updateFlag[0],
    updateFlag[1],
    updateFlag[2],
    updateFlag[3],
    updateFlag[4]
  ]);

  useEffect(() => {
    if (counter >= 1) {
      const intervalId = setInterval(() => {
        setCounter(counter - 1);
      }, 1000);
      counterRef.current = intervalId;
      return () => {
        if (counterRef.current) clearInterval(counterRef.current);
      };
    }
  }, [counter]);

  const resendMail = () => {
    serverRequest({
      endpoint: `users/forgotPassword/${location.state.email}`,
      body: {},
      method: 'post'
    }).then(() => {
      toast({
        position: 'top',
        title: 'Success',
        description: `Verification code resent successfully.`,
        status: 'success',
        duration: 3000,
        isClosable: true
      });
    });
  };

  const handleInput = (e: any, index: string) => {
    const updateInput = (index: number, cb: any) => {
      if (e.key === 'Backspace') {
        cb(index);
        const flags = updateFlag;
        flags[index] = false;
        setUpdateFlag(flags);
        return;
      }
      if ([0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(Number(e.key))) {
        cb(e.key);
        const flags = updateFlag;
        flags[index] = true;
        setUpdateFlag(flags);
        return;
      }
      return;
    };
    const updater = {
      input1: () => {
        updateInput(0, setInputValue1);
      },
      input2: () => {
        updateInput(1, setInputValue2);
      },
      input3: () => {
        updateInput(2, setInputValue3);
      },
      input4: () => {
        updateInput(3, setInputValue4);
      }
    };
    updater[index as keyof typeof updater]();
  };

  return (
    <AuthLayout title={''} subtitle={''}>
      <Text
        color={colors.deep_blue}
        fontSize={32}
        fontWeight={700}
        letterSpacing={0.25}
        fontFamily='Inter'
        marginBottom={19}
        placeholder={'0'}
      >
        {formatCounter()}
      </Text>
      <Text
        color={colors.gray}
        maxW={324}
        fontSize={19}
        fontWeight={700}
        lineHeight='122%'
        letterSpacing={0.15}
        fontFamily='Inter'
      >
        Type the verification code sent to your email
      </Text>
      <Flex mt={46}>
        <Input
          onKeyUp={(e) => handleInput(e, 'input1')}
          fontWeight={700}
          fontSize={34}
          bg={updateFlag[0] ? colors.deep_blue : '#FFF'}
          color={updateFlag[0] ? '#FFF' : 'rgba(5, 57, 107, 0.4)'}
          value={inputValue1}
          type='number'
          textAlign='center'
          min={0}
          max={1}
          w={66}
          h={70}
          border={'1px solid #E8E6EA'}
          mr={10}
          borderRadius={15}
          placeholder={'0'}
        />
        <Input
          fontWeight={700}
          fontSize={34}
          bg={updateFlag[1] ? colors.deep_blue : '#FFF'}
          color={updateFlag[1] ? '#FFF' : 'rgba(5, 57, 107, 0.4)'}
          value={inputValue2}
          onKeyUp={(e) => handleInput(e, 'input2')}
          min={1}
          textAlign='center'
          max={1}
          w={66}
          h={70}
          border={'1px solid #E8E6EA'}
          mr={10}
          borderRadius={15}
          placeholder={'0'}
        />
        <Input
          fontWeight={700}
          fontSize={34}
          bg={updateFlag[2] ? colors.deep_blue : '#FFF'}
          color={updateFlag[2] ? '#FFF' : 'rgba(5, 57, 107, 0.4)'}
          value={inputValue3}
          onKeyUp={(e) => handleInput(e, 'input3')}
          min={1}
          textAlign='center'
          max={1}
          w={66}
          h={70}
          border={'1px solid #E8E6EA'}
          mr={10}
          borderRadius={15}
          placeholder={'0'}
        />
        <Input
          fontWeight={700}
          fontSize={34}
          bg={updateFlag[3] ? colors.deep_blue : '#FFF'}
          color={updateFlag[3] ? '#FFF' : 'rgba(5, 57, 107, 0.4)'}
          value={inputValue4}
          onKeyUp={(e) => handleInput(e, 'input4')}
          min={1}
          textAlign='center'
          max={1}
          w={66}
          h={70}
          border={'1px solid #E8E6EA'}
          borderRadius={15}
          placeholder={'0'}
        />
      </Flex>
      <Button
        onClick={resendMail}
        mt={'96px'}
        textTransform='uppercase'
        fontWeight={500}
        fontFamily={'Inter'}
        fontSize={14}
        color={colors.deep_blue}
      >
        Send again
      </Button>
    </AuthLayout>
  );
};
export default VerifyResetCode;
