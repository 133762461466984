import React, { useState } from 'react';
import useRangeChange from '../../../hooks/useRangeChange';
import { Indexable } from '../../../interface';
import { getFromUrl } from '../../../api/Api';
import NationalityRatioChart from './NationalityRatioChart';
import NationalityRatioMap from './NationalityRatioMap';
import CardHeader from '../../Dashboard/CardHeader';
import { Flex, Box } from '@chakra-ui/react';
import Card from '../../Dashboard/Card';
import { colors } from '../../../theme/colors';

const NationalityRatio = () => {
  interface GraphInterface {
    name: string;
    value: number;
  }

  const [data, setData] = useState([] as GraphInterface[]);
  const [countries, setCountries] = useState<string[]>([]);
  const { range, handleChange } = useRangeChange();
  const createChartData = (object: any) => {
    const chartData: any[] = [];
    for (const key in object) {
      if (key !== 'OTHER' && key !== 'null' && key !== 'undefined') {
        const item = {
          name: key,
          value: (object as Indexable)[key]
        };
        chartData.push(item);
      }
    }
    const validCountries = chartData.filter(
      (country: any) => country.value !== 0
    );
    setCountries(validCountries);
    setData(validCountries);
  };
  const getNationalRatio = async () => {
    try {
      const { data } = await getFromUrl(
        `cases/nationality/ratio?range=${range}`
      );
      if (typeof data === 'object') {
        // filter out countries with value equal 0
        createChartData(data);
      } else {
        setData([]);
        setCountries([]);
      }
    } catch (e: any) {
      return;
    }
  };
  React.useEffect(() => {
    getNationalRatio().catch((err) => console.error(err));
  }, [range]);

  return (
    <Card w='100%'>
      <Box py='24px' width='100%'>
        <CardHeader title='Cases By Location' handleChange={handleChange} />
        {data.length ? (
          <>
            <NationalityRatioMap width={380} height={250} data={countries} />
            <NationalityRatioChart data={data} />
          </>
        ) : (
          <Flex
            justifyContent='center'
            marginX='auto'
            marginY='40px'
            width='100%'
            color={colors.gray}
          >
            No data available
          </Flex>
        )}
      </Box>
    </Card>
  );
};
export default NationalityRatio;
