import React, { useEffect, useState } from 'react';
import { Box, Flex, Grid, useToast } from '@chakra-ui/react';
import { Icon } from '../Icons';
import { colors } from '../../theme/colors';
import { Body } from '../Texts/Texts';
import Referrals from '../../components/Resources/Referrals';
import PopoverMenu from '../../components/Popover';
import ResourcesItems from '../../components/Resources/ResourcesItems';
import { getFromUrl, serverRequest } from '../../api/Api';
import { useParams } from 'react-router-dom';

const FolderResourcesSection = () => {
  const toast = useToast();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [folder, setFolder] = useState<any>([]);
  const fileInput = React.useRef<null | HTMLInputElement>(null);

  useEffect(() => {
    setLoading(true);
    getFromUrl(`resources/${id}/all/files`).then((res) => {
      setFolder(res);
      setLoading(false);
    });
  }, []);

  const selectFile = () => {
    fileInput.current?.click();
  };

  const onChangeFile = (event: any) => {
    setLoading(true);
    event.preventDefault();
    const files = event.target.files;
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file);
    }
    serverRequest({
      endpoint: `resources/${id}/files`,
      body: formData,
      method: 'post',
      type: 'formData'
    }).then((res) => {
      setLoading(false);
      if (res?.results) {
        getFromUrl(`resources/${id}/all/files`).then((res) => setFolder(res));
        toast({
          position: 'top',
          title: 'Success',
          description: `Files upload successful`,
          status: 'success',
          duration: 3000,
          isClosable: true
        });
      } else {
        toast({
          position: 'top',
          title: 'Error',
          description: 'Error uploading files',
          status: 'error',
          duration: 3000,
          isClosable: true
        });
      }
    });
  };

  return (
    <Grid
      gridTemplateColumns='2.4fr 1fr'
      gap='56px'
      mt='32px'
      pb='64px'
      pr='60px'
    >
      <Box>
        <PopoverMenu
          trigger={
            <Flex
              cursor='pointer'
              alignItems='center'
              justifyContent='center'
              color='deep_blue'
              mr='26px'
              bg='accent'
              w='244px'
              h='55px'
              borderRadius='12px'
              mb='36px'
              px='20px'
            >
              <Body
                color='white'
                textTransform='uppercase'
                size='14px'
                mr='26px'
                fontWeight={500}
              >
                Add new
              </Body>
              <Icon name='Upload' color={colors.white} size={20} filled />
            </Flex>
          }
          top='130px'
          w='334px'
          placement='top-start'
        >
          {(onClose: any) => (
            <Box fontSize='19px' fontWeight={500} color='gray'>
              <Flex
                onClick={() => {
                  onClose();
                  selectFile();
                }}
                py='14px'
                justifyContent='center'
                cursor='pointer'
              >
                Upload File
              </Flex>
            </Box>
          )}
        </PopoverMenu>
        {!loading ? (
          <ResourcesItems
            type='files'
            data={folder?.files}
            view='folderView'
            folderName={folder?.folderName}
          />
        ) : null}
        <input
          multiple={true}
          type='file'
          id='file'
          ref={fileInput}
          style={{ display: 'none' }}
          onChange={onChangeFile}
        />
      </Box>
      <Referrals />
    </Grid>
  );
};

export default FolderResourcesSection;
