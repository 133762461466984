import React from 'react';
import { Avatar, Box, Flex, Grid, GridItem } from '@chakra-ui/react';
import { colors } from '../../theme/colors';
import { Body, Caption } from '../../components/Texts/Texts';

const DetailsView = ({
  details,
  ...rest
}: {
  details: any;
  [x: string]: any;
}) => {
  return (
    <Box bg='gray_50' px='48px' py='66px' borderRadius='8px' {...rest}>
      <Flex alignItems='center' mb='50px'>
        <Avatar
          name={details.name}
          bg='accent'
          color='white'
          boxSize='36px'
          getInitials={(name) => name.charAt(0)}
        />
        <Box ml='16px'>
          <Body color='deep_blue' fontWeight={500}>
            {details.name}
          </Body>
          <Body size='14px' color='deep_blue'>
            {details.email}
          </Body>
        </Box>
      </Flex>
      <Grid gridTemplateColumns='repeat(3, 1fr)' gap='24px'>
        <GridItem>
          <Caption color='deep_blue' fontWeight={700}>
            Role
          </Caption>
          <Body color='deep_blue' textTransform='capitalize'>
            {details.role.split('_').join(' ')}
          </Body>
        </GridItem>
        <GridItem>
          <Caption color='deep_blue' fontWeight={700}>
            Date of birth
          </Caption>
          <Body color='deep_blue'>{details.dob || '--'}</Body>
        </GridItem>
        <GridItem>
          <Caption color='deep_blue' fontWeight={700}>
            Gender
          </Caption>
          <Body color='deep_blue'>{details.gender || '--'}</Body>
        </GridItem>
        <GridItem>
          <Caption color='deep_blue' fontWeight={700}>
            Phone Number
          </Caption>
          <Body color='deep_blue'>{details.phone || '--'}</Body>
        </GridItem>
        <GridItem colSpan={2}>
          <Caption color='deep_blue' fontWeight={700}>
            Address
          </Caption>
          <Body color='deep_blue'>{details.address || '--'}</Body>
        </GridItem>
      </Grid>
      {/*COUNSELLOR SESSION SUMMARY*/}
      {details.role === 'COUNSELLOR' || details.role === 'SUPERVISOR' ? (
        <Grid
          gridTemplateColumns='repeat(3, 1fr)'
          gap='24px'
          borderTop={`1px solid ${colors.tint}`}
          mt='22px'
          pt='24px'
        >
          <GridItem>
            <Caption color='deep_blue' fontWeight={700}>
              Active
            </Caption>
            <Body color='deep_blue'>{details.active || '--'}</Body>
          </GridItem>
          <GridItem>
            <Caption color='deep_blue' fontWeight={700}>
              Ongoing
            </Caption>
            <Body color='deep_blue'>{details.ongoing || '--'}</Body>
          </GridItem>
          <GridItem>
            <Caption color='deep_blue' fontWeight={700}>
              Closed
            </Caption>
            <Body color='deep_blue'>{details.closed || '--'}</Body>
          </GridItem>
        </Grid>
      ) : null}
    </Box>
  );
};

export default DetailsView;
