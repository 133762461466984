import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { Body, Overline } from '../Texts/Texts';

const CounsellorTableItem = ({
  counsellor,
  isEven,
  actionItem
}: {
  counsellor: any;
  isEven: boolean;
  actionItem?: any;
}) => {
  const ActionItem = actionItem;
  return (
    <Flex
      h='85px'
      borderRadius='12px'
      bg={isEven ? 'white' : 'transparent'}
      alignItems='center'
      px='22px'
    >
      <Body size='16px' flex={1} color='deep_blue'>
        {counsellor.name}
      </Body>
      <Body size='16px' flex={1} color='deep_blue'>
        {counsellor.email}
      </Body>
      <Flex flex={1} mt='-18px' alignItems='flex-end'>
        <Box mr='24px'>
          <Overline type='small' mb='8px' color='deep_blue'>
            Active
          </Overline>
          <Body size='16px' color='deep_blue'>
            {counsellor.sessionSummary?.active | counsellor?.active}
          </Body>
        </Box>
        <Box mr='24px'>
          <Overline type='small' mb='8px' color='deep_blue'>
            Ongoing
          </Overline>
          <Body size='16px' color='deep_blue'>
            {counsellor.sessionSummary?.ongoing | counsellor.ongoing}
          </Body>
        </Box>
        <Box mr='auto'>
          <Overline type='small' mb='8px' color='deep_blue'>
            Closed
          </Overline>
          <Body size='16px' color='deep_blue'>
            {counsellor.sessionSummary?.closed | counsellor.closed}
          </Body>
        </Box>
        {Boolean(actionItem) && <ActionItem counsellorData={counsellor} />}
        {/* <Box
          boxSize="20px"
          borderRadius="5px"
          borderWidth="2px"
          borderColor="deep_blue"
          mr="12px"
          cursor="pointer"
          bg={isSelected ? "deep_blue" : "transparent"}
          onClick={selectItem}
        ></Box> */}
      </Flex>
    </Flex>
  );
};

export default CounsellorTableItem;
