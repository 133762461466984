import { Checkbox, Flex, Link, Spacer } from '@chakra-ui/react';
import React from 'react';
import { Link as ReachLink, useLocation, useNavigate } from 'react-router-dom';
import { serverRequest } from '../../api/Api';
import { PrimaryButton } from '../../components/Buttons/Buttons';
import { TextField } from '../../components/Inputs/TextField';
import { AuthContext } from '../../context/AuthContext';
import AuthLayout from '../../layouts/auth/AuthLayout';
import { noErrors } from '../../utils/helper';

const SignIn = () => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const authContext = React.useContext(AuthContext);

  const from = location.state?.from?.pathname || '/dashboard';

  const [authDetails, setAuthDetails] = React.useState({
    username: '',
    password: ''
  });

  const [errors, setErrors] = React.useState({
    username: '',
    password: '',
    invalidCredentials: ''
  });

  const [loading, setLoading] = React.useState(false);

  const handleDetails = (key: string, value: string) => {
    setAuthDetails({
      ...authDetails,
      [key]: value
    });
    setErrors({
      ...errors,
      [key]: ''
    });
  };

  const login = async () => {
    const emptyFields = noErrors(authDetails);
    if (emptyFields) {
      if (!authDetails.username) {
        setErrors((prev) => ({
          ...prev,
          username: 'Please enter your email address.'
        }));
      }
      if (!authDetails.password) {
        setErrors((prev) => ({
          ...prev,
          password: 'A password is required to log in.'
        }));
      }
      return;
    }

    try {
      setLoading(true);
      const response = await serverRequest({
        endpoint: 'auth/admin/login',
        body: authDetails,
        method: 'POST'
      });
      loginAction(response);
      setLoading(false);
    } catch (e: any) {
      setErrors((prev) => ({
        ...prev,
        invalidCredentials: 'Email or password incorrect'
      }));
      setLoading(false);
    }
  };

  const loginAction = (data: any) => {
    authContext?.signIn(data.token, JSON.stringify(data.user), () =>
      navigate(from, { replace: true })
    );
    localStorage.setItem('user', JSON.stringify(data.user));
    if (
      data.user.phone == undefined &&
      data.user.gender == undefined &&
      data.user.address == undefined
    ) {
      navigate('/authentication/create-password', {
        state: {
          authDetails,
          skip: true
        }
      });
    }
  };

  return (
    <AuthLayout
      title='Welcome'
      subtitle='Enter your Email and Password to sign in.'
      error={errors.invalidCredentials}
    >
      <TextField
        label='email address'
        onChange={(value: string) => handleDetails('username', value)}
        value={authDetails.username}
        error={errors.username || errors.invalidCredentials}
      />
      <TextField
        label='password'
        onChange={(value: string) => handleDetails('password', value)}
        value={authDetails.password}
        type='password'
        error={errors.password || errors.invalidCredentials}
      />
      <Flex alignItems='center'>
        <Checkbox colorScheme='blue' borderColor='#DBE2EA' marginRight='10px'>
          Remember me
        </Checkbox>

        <Spacer />
        <Link
          as={ReachLink}
          to='/authentication/forgot-password'
          color='#0880AE'
          fontSize='12px'
        >
          Forgot Password?
        </Link>
      </Flex>
      <PrimaryButton
        label='Sign in'
        mt='20px'
        onClick={login}
        isLoading={loading}
      />
    </AuthLayout>
  );
};

export default SignIn;
