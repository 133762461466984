import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { colors } from '../../theme/colors';
import { Icon } from '../Icons';
import { Body, Headline } from '../Texts/Texts';
import { Link } from 'react-router-dom';
import PopoverMenu from '../Popover';
import Menu from '../../assets/svg/menu.svg';
import { serverRequest } from '../../api/Api';
import ModalView from '../Modal';
import { TextField } from '../Inputs/TextField';
import { PrimaryButton, TextButton } from '../Buttons/Buttons';

interface FolderCardProps {
  name: string;
  id: string;
  refetch?: any;
}

const FolderCard = ({ name, id, refetch }: FolderCardProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [folderName, setFolderName] = useState('');
  const toast = useToast();
  const {
    isOpen: isRModalOpen,
    onOpen: onRModalOpen,
    onClose: onRModalClose
  } = useDisclosure();
  const {
    isOpen: isDModalOpen,
    onOpen: onDModalOpen,
    onClose: onDModalClose
  } = useDisclosure();

  const renameFolder = (e: any) => {
    e.preventDefault();
    onRModalClose();
    setIsLoading(true);
    serverRequest({
      endpoint: `resources/folder/${id}`,
      method: 'put',
      body: { folderName }
    }).then(() => {
      setIsLoading(false);
      refetch();
    });
  };
  const deleteFolder = () => {
    onDModalClose();
    serverRequest({
      endpoint: `resources/folder/${id}`,
      body: {},
      method: 'delete'
    }).then((res) => {
      if (!res.error) {
        refetch();
        toast({
          position: 'top',
          title: 'Success',
          description: `File successfully deleted.`,
          status: 'success',
          duration: 3000,
          isClosable: true
        });
      } else {
        toast({
          position: 'top',
          title: 'Error',
          description: 'Error deleting folder',
          status: 'error',
          duration: 3000,
          isClosable: true
        });
      }
    });
  };

  return (
    <Flex
      h='mac-content'
      bg='white'
      boxShadow='0px 3.5px 5.5px rgba(0, 0, 0, 0.02)'
      borderRadius='15px'
      flexDirection='row'
      px='24px'
      py='28px'
      justifyContent='space-between'
      alignItems='flex-start'
    >
      <Link to={`/resources/${id}`}>
        <Flex flexDirection='column'>
          <Icon
            name='Folder'
            color={colors.deep_blue}
            size={20}
            filled
            margin={0}
            marginBottom={'26px'}
          />
          <Body color='deep_blue' size='16px'>
            {name}
          </Body>
        </Flex>
      </Link>

      <PopoverMenu
        trigger={<Image src={Menu} color={colors.deep_blue} boxSize={'24px'} />}
        top='50px'
        w='240px'
        left='-140px'
        placement='bottom-start'
      >
        {(onClose: any) => (
          <Box fontSize='19px' fontWeight={500} color='gray'>
            <Flex
              onClick={() => {
                onClose();
                onRModalOpen();
              }}
              py='14px'
              justifyContent='center'
              cursor='pointer'
              fontSize='19px'
            >
              Rename
            </Flex>
            <Flex
              onClick={() => {
                onClose();
                onDModalOpen();
              }}
              fontSize='19px'
              py='14px'
              justifyContent='center'
              cursor='pointer'
            >
              Delete
            </Flex>
          </Box>
        )}
      </PopoverMenu>
      <ModalView
        isOpen={isRModalOpen}
        onClose={onRModalClose}
        bg='gray_50'
        maxW='600px'
        noCloseBtn
      >
        <Box bg='gray_50' px='86px' py='53px' borderRadius='8px'>
          <Headline size='19px' color='gray' mb='28px'>
            Rename file
          </Headline>
          <form onSubmit={renameFolder}>
            <TextField
              label='Folder name'
              value={folderName}
              onChange={setFolderName}
              bg='white'
              required={true}
            />
            <PrimaryButton
              label='Rename Folder'
              isLoading={isLoading}
              type={'submit'}
            />
            <TextButton label='cancel' mt='24px' onClick={onRModalClose} />
          </form>
        </Box>
      </ModalView>
      <ModalView
        isOpen={isDModalOpen}
        onClose={onDModalClose}
        bg='gray_50'
        maxW='600px'
        noCloseBtn
      >
        <Box bg='gray_50' px='86px' py='53px' borderRadius='8px'>
          <Text fontSize='19px' color='gray' mb='28px' textAlign='center'>
            {`Are you sure you want to permanently delete ${name}?`}
          </Text>
          <Flex justifyContent='center' gap='16px'>
            <Button
              onClick={deleteFolder}
              bg='#FF7171'
              color='white'
              py='22px'
              px='32px'
            >
              YES
            </Button>
            <Button
              onClick={onDModalClose}
              bg={colors.accent}
              py='22px'
              px='32px'
              color='white'
            >
              NO
            </Button>
          </Flex>
        </Box>
      </ModalView>
    </Flex>
  );
};

export default FolderCard;
