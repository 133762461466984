import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { colors } from '../../theme/colors';
import { Icon } from '../Icons';
import { Body, Headline } from '../Texts/Texts';
import PopoverMenu from '../Popover';
import Menu from '../../assets/svg/menu.svg';
import { serverRequest } from '../../api/Api';
import { TextField } from '../Inputs/TextField';
import { PrimaryButton, TextButton } from '../Buttons/Buttons';
import ModalView from '../Modal';

interface FileCardProps {
  name: string;
  url: string;
  id: string;
  refetch?: any;
}

const FileCard = ({ name, url, id, refetch }: FileCardProps) => {
  const toast = useToast();
  const {
    isOpen: isRModalOpen,
    onOpen: onRModalOpen,
    onClose: onRModalClose
  } = useDisclosure();
  const {
    isOpen: isDModalOpen,
    onOpen: onDModalOpen,
    onClose: onDModalClose
  } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState('');
  const deleteFile = () => {
    onDModalClose();
    serverRequest({
      endpoint: `resources/file/${id}`,
      body: {},
      method: 'delete'
    }).then((res) => {
      if (!res.error) {
        refetch();
        toast({
          position: 'top',
          title: 'Success',
          description: `File successfully deleted.`,
          status: 'success',
          duration: 3000,
          isClosable: true
        });
      } else {
        toast({
          position: 'top',
          title: 'Error',
          description: 'Error deleting file',
          status: 'error',
          duration: 3000,
          isClosable: true
        });
      }
    });
  };
  const downloadFile = () => {
    serverRequest({
      endpoint: `resources/download/file`,
      body: {
        fileUrl: url
      },
      method: 'post',
      type: 'file'
    }).then((res) => {
      const link = document.createElement('a');
      link.download = name;
      link.href = URL.createObjectURL(res);
      link.click();
      if (!res.error) {
        refetch();
        toast({
          position: 'top',
          title: 'Success',
          description: `File successfully downloaded.`,
          status: 'success',
          duration: 3000,
          isClosable: true
        });
      } else {
        toast({
          position: 'top',
          title: 'Error',
          description: 'Error downloading file',
          status: 'error',
          duration: 3000,
          isClosable: true
        });
      }
    });
  };
  const renameFile = (e: any) => {
    e.preventDefault();
    onRModalClose();
    setIsLoading(true);
    serverRequest({
      endpoint: `resources/${id}`,
      method: 'put',
      body: { fileName }
    }).then(() => {
      setIsLoading(false);
      refetch();
    });
  };

  return (
    <Flex
      w='100%'
      h='max-content'
      bg='white'
      boxShadow='0px 3.5px 5.5px rgba(0, 0, 0, 0.02)'
      borderRadius='15px'
      flexDirection='row'
      px='24px'
      py='28px'
      alignItems='flex-start'
      justifyContent='space-between'
    >
      <a
        href={url}
        rel='noopener noreferrer'
        target='_blank'
        style={{ width: '100%' }}
      >
        <Flex w='100%'>
          {' '}
          <Icon
            name='Paper'
            color={colors.deep_blue}
            size={20}
            filled
            mr='12px'
          />
          <Body color='deep_blue'>{name}</Body>
        </Flex>
      </a>
      <Flex w={'48px'} h={'48px'} justifyContent={'flex-end'}>
        <PopoverMenu
          trigger={<Image src={Menu} boxSize={'24px'} />}
          top='40px'
          left='-140px'
          w='334px'
          placement='right-start'
        >
          {(onClose: any) => (
            <Box fontSize='19px' fontWeight={500} color='gray'>
              <Flex
                onClick={() => {
                  onClose();
                  onRModalOpen();
                }}
                py='14px'
                justifyContent='center'
                cursor='pointer'
                fontSize='19px'
              >
                Rename
              </Flex>
              <Flex
                onClick={() => {
                  onClose();
                  downloadFile();
                }}
                py='14px'
                justifyContent='center'
                cursor='pointer'
                fontSize='19px'
              >
                Download
              </Flex>

              <Flex
                onClick={() => {
                  onClose();
                  onDModalOpen();
                }}
                fontSize='19px'
                py='14px'
                justifyContent='center'
                cursor='pointer'
              >
                Delete
              </Flex>
            </Box>
          )}
        </PopoverMenu>
      </Flex>
      <ModalView
        isOpen={isRModalOpen}
        onClose={onRModalClose}
        bg='gray_50'
        maxW='600px'
        noCloseBtn
      >
        <Box bg='gray_50' px='86px' py='53px' borderRadius='8px'>
          <Headline size='19px' color='gray' mb='28px'>
            Rename file
          </Headline>
          <form onSubmit={renameFile}>
            <TextField
              label='File name'
              value={fileName}
              onChange={setFileName}
              bg='white'
              required={true}
            />
            <PrimaryButton
              label='Rename File'
              isLoading={isLoading}
              type={'submit'}
            />
            <TextButton label='cancel' mt='24px' onClick={onRModalClose} />
          </form>
        </Box>
      </ModalView>
      <ModalView
        isOpen={isDModalOpen}
        onClose={onDModalClose}
        bg='gray_50'
        maxW='600px'
        noCloseBtn
      >
        <Box bg='gray_50' px='86px' py='53px' borderRadius='8px'>
          <Text fontSize='19px' color='gray' mb='28px' textAlign='center'>
            {`Are you sure you want to permanently delete ${name}?`}
          </Text>
          <Flex justifyContent='center' gap='16px'>
            <Button
              onClick={deleteFile}
              bg='#FF7171'
              color='white'
              py='22px'
              px='32px'
            >
              YES
            </Button>
            <Button
              onClick={onDModalClose}
              bg={colors.accent}
              py='22px'
              px='32px'
              color='white'
            >
              NO
            </Button>
          </Flex>
        </Box>
      </ModalView>
    </Flex>
  );
};

export default FileCard;
