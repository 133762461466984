import React, { useState } from 'react';
import { PrimaryButton } from '../../components/Buttons/Buttons';
import { TextField } from '../../components/Inputs/TextField';
import AuthLayout from '../../layouts/auth/AuthLayout';
import { serverRequest } from '../../api/Api';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  const resetPassword = () => {
    serverRequest({
      endpoint: `users/forgotPassword/${email}`,
      body: {},
      method: 'post'
    }).then(() => {
      setTimeout(
        () =>
          navigate('/authentication/verify-reset-code', { state: { email } }),
        500
      );
    });
  };

  return (
    <AuthLayout
      title='Forgot Password'
      subtitle='Enter your email address and we will send you reset instruction'
    >
      <TextField label='email address' onChange={setEmail} value={email} />
      <PrimaryButton
        label='reset password'
        mt='24px'
        onClick={() => resetPassword()}
      />
    </AuthLayout>
  );
};

export default ForgotPassword;
