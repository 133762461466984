import { Flex } from '@chakra-ui/react';

interface CardProps {
  children: any;
  [x: string]: any;
}

const Card = ({ children, ...rest }: CardProps) => {
  return (
    <Flex
      bg='white'
      borderRadius='15px'
      boxShadow='0px 3.5px 5.5px rgba(0, 0, 0, 0.02)'
      px='32px'
      {...rest}
    >
      {children}
    </Flex>
  );
};

export default Card;
