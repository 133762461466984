import React, { useEffect, useState } from 'react';
import { DashboardContext } from './DashboardContext';
import { getFromUrl } from '../api/Api';

const DashboardProvider = ({ children }: { children: React.ReactNode }) => {
  const [notifications, setNotifications] = useState<any>([]);
  useEffect(() => {
    getFromUrl('notifications').then((res) => {
      setNotifications(res);
    });
  }, []);

  return (
    <DashboardContext.Provider value={{ notifications }}>
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;
