import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDescription,
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
  Text
} from '@chakra-ui/react';
import useServerRequest from 'hooks/useServerRequest';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ILocationState } from 'types';
import { getFromUrl, serverRequest } from '../../api/Api';
import UserItem from '../../components/Accounts/UserItem';
import { PrimaryButton } from '../../components/Buttons/Buttons';
import DetailsView from '../../components/Counsellors/DetailsView';
import { Check, Icon } from '../../components/Icons';
import { TextField } from '../../components/Inputs/TextField';
import PopoverMenu from '../../components/Popover';
import Tabs from '../../components/Tabs';
import { Body, Subtitle } from '../../components/Texts/Texts';
import DashboardLayout from '../../layouts/dashboard/DashboardLayout';
import { colors } from '../../theme/colors';
import { hexToRgbA } from '../../utils/helper';
import { Link } from 'react-router-dom';

const RightAccessory = ({
  userId,
  deleteAccount,
  userType,
  setUserType
}: {
  deleteAccount?: (id: string) => void;
  userId?: string;
  userType: string;
  setUserType: Dispatch<SetStateAction<string>>;
}) => {
  const types = ['admin', 'super admin', 'supervisor', 'researcher'];

  return (
    <PopoverMenu
      trigger={
        <Flex alignItems='center' minW='150px' cursor='pointer'>
          <Subtitle mr='20px' ml='auto' size='14px' textTransform='capitalize'>
            {userType}
          </Subtitle>
          <ChevronDownIcon />
        </Flex>
      }
      placement='bottom-end'
    >
      {(onClose: any) => (
        <Box px='18px' py='18px'>
          <Body size='14px' color='black' mb='15px'>
            User type:
          </Body>
          {types.map((item, index) => {
            const isSelected = item === userType;
            return (
              <Flex
                key={item + index}
                h='48px'
                justifyContent='space-between'
                alignItems='center'
                bg={isSelected ? hexToRgbA(colors.black, '0.1') : 'transparent'}
                pos='relative'
                cursor='pointer'
                onClick={() => {
                  setUserType(item);
                  onClose();
                }}
                px='8px'
              >
                <Subtitle textTransform='capitalize'>{item}</Subtitle>
                {isSelected && <Check />}
              </Flex>
            );
          })}
          {userId && deleteAccount ? (
            <Text
              px='8px'
              py='12px'
              cursor='pointer'
              onClick={() => {
                deleteAccount(userId);
                onClose();
              }}
            >
              Remove
            </Text>
          ) : null}
        </Box>
      )}
    </PopoverMenu>
  );
};

const Accounts = () => {
  const toast = useToast();
  const [loadingInvite, setLoadingInvite] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState('users');
  const [userType, setUserType] = React.useState('admin');
  const [profile, setProfile] = React.useState(null);
  const [admins, setAdmins] = React.useState([]);
  const [email, setEmail] = React.useState('');
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const { error, isLoading, data, sendRequest } = useServerRequest();
  const location = useLocation();

  React.useEffect(() => {
    getAdmins().catch((err) => console.error(err));
  }, []);

  React.useEffect(() => {
    getProfile().catch((err) => console.error(err));
  }, [activeTab]);

  useEffect(() => {
    if (data && !error && !isLoading) {
      onClose();
    }
  }, [data, error, isLoading, onClose]);

  useEffect(() => {
    const state = (location.state as ILocationState) || 'users';
    if (state.activeTab) {
      setActiveTab(state.activeTab);
    }
  }, [location]);

  const getProfile = async () => {
    try {
      const profile = await getFromUrl('users/profile');
      setProfile(profile);
    } catch (e: any) {
      return;
    }
  };

  const getAdmins = async () => {
    try {
      const admins = await getFromUrl('users/admins');
      if (admins !== undefined) setAdmins(admins);
    } catch (e: any) {
      return;
    }
  };

  const updateProfile = async () => {
    await sendRequest('users/profile', {
      body: {
        address,
        phone: phoneNumber
      },
      method: 'PUT'
    }).catch((err) => console.error(err));
  };
  const createAdmin = async () => {
    if (email) {
      const body = {
        email,
        role: userType.replace(' ', '_').toUpperCase()
      };
      try {
        setLoadingInvite(true);
        const response = await serverRequest({
          endpoint: 'users/create',
          body,
          method: 'POST'
        });
        if (response.status === 400) {
          toast({
            position: 'top',
            title: 'Error',
            description: 'User already exists',
            status: 'error',
            duration: 3000,
            isClosable: true
          });
        } else {
          await getAdmins();
          toast({
            position: 'top',
            title: 'Success',
            description: `You've sent an invite to ${email}`,
            status: 'success',
            duration: 3000,
            isClosable: true
          });
        }
        setLoadingInvite(false);
        setEmail('');
        return response;
      } catch (e: any) {
        setLoadingInvite(false);
      }
    }
  };
  const updateUserRole = async (body: unknown) => {
    await sendRequest('users/profile', {
      body,
      method: 'PUT'
    }).catch((err) => console.error(err));
  };
  const deleteAccount = (userId: string) => {
    serverRequest({
      endpoint: `users/delete/admin/${userId}`,
      body: {},
      method: 'DELETE'
    })
      .then((res: any) => {
        if (res?.status === 400 || res?.status === 403) {
          toast({
            position: 'top',
            title: 'Error',
            description: 'Error deleting user',
            status: 'error',
            duration: 3000,
            isClosable: true
          });
        } else {
          getAdmins().catch((err) => console.error(err));
          toast({
            position: 'top',
            title: 'Success',
            description: 'User successfully deleted',
            status: 'success',
            duration: 3000,
            isClosable: true
          });
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    updateUserRole({
      role: userType.split(' ').join('_').toUpperCase()
    }).catch((err) => console.error(err));
  }, [userType]);

  return (
    <DashboardLayout pageTitle='Accounts'>
      <Box pr='60px' pb='30px'>
        <Tabs
          tabs={['users', 'my profile']}
          activeTab={activeTab}
          tabAction={setActiveTab}
        />
        {activeTab === 'users' && (
          <Box maxW='770px'>
            <Flex
              mt='32px'
              pb='30px'
              mb='50px'
              borderBottom={`1px solid ${colors.shade}`}
            >
              <TextField
                label='Type email here...'
                value={email}
                onChange={setEmail}
                bg='white'
                rightAccessory={
                  <RightAccessory
                    userType={userType}
                    setUserType={setUserType}
                  />
                }
              />
              <PrimaryButton
                label='Send invite'
                width='166px'
                ml='16px'
                fontSize='14px'
                minW='166px'
                isLoading={loadingInvite}
                onClick={createAdmin}
              />
            </Flex>
            {admins.map((user: any) => {
              return (
                <UserItem
                  key={user._id}
                  user={user}
                  options={
                    <RightAccessory
                      deleteAccount={deleteAccount}
                      userId={user._id}
                      userType={user.role.split('_').join(' ').toLowerCase()}
                      setUserType={setUserType}
                    />
                  }
                />
              );
            })}
          </Box>
        )}
        {activeTab === 'my profile' && profile && (
          <>
            <Flex pt='50px'>
              <DetailsView details={profile} px='0' py='0' width='80%' />
              <Flex onClick={onOpen} height='fit-content' cursor='pointer'>
                <Icon name='Edit' color={colors.deep_blue} size={18} filled />
                <Subtitle color='deep_blue' ml='10px'>
                  Edit
                </Subtitle>
              </Flex>
            </Flex>
            <Link to='/authentication/create-password' state={{ skip: false }}>
              <Button
                fontWeight={500}
                variant='outline'
                borderColor={colors.tint}
                fontSize='0.875rem'
                color={colors.deep_blue}
                marginTop='1.5rem'
                bg='#FFF'
              >
                Change Password
              </Button>
            </Link>

            <Modal isOpen={isOpen} onClose={onClose} size='xl' isCentered>
              <ModalOverlay />
              <ModalContent px='87px' py='56px'>
                <ModalHeader color='#756F86' textAlign='center'>
                  Edit Profile
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Stack>
                    <Flex>
                      <Stack maxW='125px' mr='10px'>
                        <TextField
                          label='Country code'
                          onChange={setCountryCode}
                          value={countryCode}
                          fontSize='14px'
                        />
                      </Stack>
                      <TextField
                        label='Phone number'
                        onChange={setPhoneNumber}
                        value={phoneNumber}
                      />
                    </Flex>
                    <TextField
                      label='Address'
                      onChange={setAddress}
                      value={address}
                    />
                    {error && (
                      <Alert status='error' variant='subtle'>
                        <AlertDescription>{error as string}</AlertDescription>
                      </Alert>
                    )}

                    <Stack pt='20px'>
                      <Button
                        colorScheme='blue'
                        mr={3}
                        backgroundColor='#05396B'
                        height='55px'
                        borderRadius='12px'
                        mb={3}
                        textTransform='uppercase'
                        onClick={updateProfile}
                        isLoading={isLoading}
                        disabled={isLoading}
                      >
                        Save
                      </Button>
                      <Button
                        height='55px'
                        onClick={onClose}
                        textTransform='uppercase'
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </Stack>
                </ModalBody>
              </ModalContent>
            </Modal>
          </>
        )}
      </Box>
    </DashboardLayout>
  );
};

export default Accounts;
