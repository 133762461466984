import { TextField } from '../../components/Inputs/TextField';
import { PrimaryButton } from '../../components/Buttons/Buttons';
import AuthLayout from '../../layouts/auth/AuthLayout';
import { useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { getList } from 'country-list-with-dial-code-and-flag';
import { Flex, Radio, RadioGroup, Select } from '@chakra-ui/react';
import { colors } from '../../theme/colors';
import { serverRequest } from '../../api/Api';

const CreateProfile = () => {
  const navigate = useNavigate();
  const [fullName, setFullName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [gender, setGender] = useState('');
  const countryList = useMemo(() => getList(), []);
  const sortedList = countryList.sort(
    (a, b) =>
      parseInt(a.dial_code.replace('+', '')) -
      parseInt(b.dial_code.replace('+', ''))
  );
  return (
    <AuthLayout title='Create Profile'>
      <form
        onSubmit={() => {
          if (fullName && emailAddress && gender && countryCode) {
            const body = {
              fullName,
              emailAddress,
              phone: countryCode + phoneNumber,
              gender
            };
            serverRequest({
              endpoint: `users/profile`,
              body,
              method: 'PUT'
            }).then((res) => {
              if (res) {
                navigate('/dashboard');
              }
            });
          }
          navigate('/dashboard');
        }}
      >
        <TextField
          label='full name'
          onChange={setFullName}
          value={fullName}
          required={true}
        />
        <TextField
          label='email address'
          onChange={setEmailAddress}
          value={emailAddress}
          required={true}
        />
        <Flex alignItems='flex-start'>
          <Select
            borderColor={colors.input_gray}
            maxW='max-content'
            marginRight='7.75px'
            size='lg'
            onChange={(e) => setCountryCode(e.target.value)}
            required={true}
            h='64px'
            borderRadius='12px'
          >
            <option value={countryCode} disabled={true}>
              ...
            </option>
            {sortedList.map(({ dial_code, flag }) => (
              <option
                value={dial_code}
                key={dial_code}
                style={{ padding: '1rem' }}
              >
                {flag} {dial_code}
              </option>
            ))}
          </Select>
          <TextField
            label='phone number'
            onChange={setPhoneNumber}
            value={phoneNumber}
            required={true}
          />
        </Flex>
        <Flex>
          <RadioGroup onChange={setGender} value={gender} my='1.5rem'>
            <span
              style={{
                border: `1px solid ${colors.input_gray}`,
                padding: '1rem',
                borderRadius: '12px',
                marginRight: '7.75px'
              }}
            >
              <Radio value='MALE' color={colors.input_gray}>
                Male
              </Radio>
            </span>
            <span
              style={{
                border: `1px solid ${colors.input_gray}`,
                padding: '1rem',
                borderRadius: '12px',
                marginRight: '7.75px'
              }}
            >
              <Radio value='FEMALE' color={colors.input_gray}>
                Female
              </Radio>
            </span>
            <span
              style={{
                border: `1px solid ${colors.input_gray}`,
                padding: '1rem',
                borderRadius: '12px',
                marginRight: '7.75px'
              }}
            >
              <Radio value='OTHER' color={colors.input_gray}>
                Other
              </Radio>
            </span>
          </RadioGroup>
        </Flex>
        <PrimaryButton label='SAVE' mt='24px' type='submit' />
      </form>
    </AuthLayout>
  );
};

export default CreateProfile;
