import useServerRequest from 'hooks/useServerRequest';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { INotification, INotifications, IUser } from 'types';
export default function useDashboard() {
  const { data = [], isLoading, error, sendRequest } = useServerRequest();
  const [formattedData, setFormattedData] = useState<INotifications>();

  useEffect(() => {
    (async () => {
      await sendRequest('notifications');
    })();
  }, [sendRequest]);

  useEffect(() => {
    /**
     * The output will be an object with date as keys && values a a list on
     * notifications that were created on that date
     * {date:[notificationItems....]}
     */
    if (data && Array.isArray(data)) {
      setFormattedData(
        data.reduce((accumulator: INotifications, item: INotification) => {
          accumulator = {
            ...(accumulator || {}), // maintain the previous values
            [moment(item.createdAt).format('L')]: [
              ...(accumulator[moment(item.createdAt).format('L')] || []), // destructure previous notifications with the same date if any
              item // add another notification item that has happened on the same date to an array
            ]
          };

          return accumulator;
        }, {})
      );
    }
  }, [data]);

  return {
    data,
    isLoading,
    error,
    formattedData,
    currentUser: JSON.parse(localStorage.getItem('user') || '{}') as IUser
  };
}
