import DashboardLayout from '../../layouts/dashboard/DashboardLayout';
import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import Tabs from '../../components/Tabs';
import CommunitySection from './CommunitySection';
import { colors } from '../../theme/colors';
import FolderResourcesSection from '../../components/Resources/FolderResourcesSection';

const FolderView = () => {
  const [activeTab, setActiveTab] = React.useState('resources');
  return (
    <DashboardLayout pageTitle='Resources'>
      <Box mt='-34px'>
        <Flex
          borderBottom={
            activeTab === 'community' ? `1px solid ${colors.shade}` : 'none'
          }
        >
          <Tabs
            tabs={['resources', 'community']}
            activeTab={activeTab}
            tabAction={setActiveTab}
          />
        </Flex>
        {activeTab === 'resources' && <FolderResourcesSection />}
        {activeTab === 'community' && <CommunitySection />}
      </Box>
    </DashboardLayout>
  );
};

export default FolderView;
