import { Flex } from '@chakra-ui/react';
import { colors } from '../../theme/colors';
import { Body } from '../Texts/Texts';
import { useNavigate, useLocation } from 'react-router-dom';
import IconBox from '../IconBox';

interface SidebarItemProps {
  label: string;
  icon: string;
  path: string;
}

const SidebarItem = ({ label, icon, path }: SidebarItemProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const activeLink = pathname.includes(label.toLowerCase());

  return (
    <Flex
      bg={activeLink ? 'white' : 'transparent'}
      borderRadius='16px'
      boxShadow={activeLink ? '0px 2px 4px rgba(8, 35, 48, 0.04)' : 'none'}
      alignItems='center'
      px='16px'
      h='54'
      maxW='220px'
      w='220px'
      cursor='pointer'
      onClick={() => navigate(path)}
      mb='10px'
      transition='0.2s ease'
    >
      <IconBox
        boxSize='30px'
        bg={activeLink ? 'light_green' : 'dark_green'}
        icon={icon}
        iconColor={activeLink ? colors.dark_green : colors.light_green}
        iconSize={18}
      />
      <Body
        size='14px'
        color='deep_blue'
        fontWeight={activeLink ? 500 : 400}
        ml='12px'
      >
        {label}
      </Body>
    </Flex>
  );
};

export default SidebarItem;
