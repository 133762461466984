import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { Icon } from '../Icons';
import { colors } from '../../theme/colors';
import { Body, Headline, Subtitle } from '../Texts/Texts';
import Menu from '../../assets/svg/menu-blue.svg';
import { TextField } from '../Inputs/TextField';
import { PrimaryButton, TextButton } from '../Buttons/Buttons';
import ModalView from '../Modal';
import PopoverMenu from '../Popover';
import { serverRequest } from '../../api/Api';

interface ReferralInterface {
  _id: string;
  name: string;
  address: string;
  website: string;
  country_code: string;
  phone: string;
  __v: number;
  id: string;
}

const ItemDetails = ({
  icon,
  value,
  isLink
}: {
  icon: string;
  value: string;
  isLink?: boolean;
}) => (
  <Flex mb='4px'>
    <Icon
      name={icon}
      color={colors.deep_blue}
      size={14}
      filled
      mr='12px'
      mt='3px'
    />
    {isLink ? (
      <Link fontSize='14px' color='deep_blue' href={value}>
        {value}
      </Link>
    ) : (
      <Body size='14px' color='deep_blue' w='188px'>
        {value}
      </Body>
    )}
  </Flex>
);

const ReferralItem = ({
  data,
  refetch
}: {
  data: ReferralInterface;
  refetch: any;
}) => {
  const [name, setName] = useState(data.name);
  const [address, setAddress] = useState(data.address);
  const [phone, setPhone] = useState(data.phone);
  const [countryCode, setCountryCode] = useState(data.country_code);
  const [website, setWebsite] = useState(data.website);
  const [isLoading, setIsLoading] = useState(false);

  const {
    isOpen: isRefModalOpen,
    onOpen: onRefModalOpen,
    onClose: onRefModalClose
  } = useDisclosure();
  const {
    isOpen: isDModalOpen,
    onOpen: onDModalOpen,
    onClose: onDModalClose
  } = useDisclosure();
  const toast = useToast();

  const editReferral = (e: any) => {
    e.preventDefault();
    onRefModalClose();
    setIsLoading(true);
    serverRequest({
      endpoint: `referral/${data._id}`,
      method: 'put',
      body: {
        name,
        phone,
        address,
        countryCode,
        website
      }
    }).then((res) => {
      setIsLoading(false);
      if (!res.error) {
        refetch();
        toast({
          position: 'top',
          title: 'Success',
          description: `Referral successfully updated.`,
          status: 'success',
          duration: 3000,
          isClosable: true
        });
      } else {
        toast({
          position: 'top',
          title: 'Error',
          description: 'Error updating referral',
          status: 'error',
          duration: 3000,
          isClosable: true
        });
      }
    });
  };

  const deleteReferral = () => {
    onDModalClose();
    serverRequest({
      endpoint: `referral/${data._id}`,
      body: {},
      method: 'delete'
    }).then((res) => {
      if (!res.error) {
        refetch();
        toast({
          position: 'top',
          title: 'Success',
          description: `Referral successfully deleted.`,
          status: 'success',
          duration: 3000,
          isClosable: true
        });
      } else {
        toast({
          position: 'top',
          title: 'Error',
          description: 'Error deleting referral',
          status: 'error',
          duration: 3000,
          isClosable: true
        });
      }
    });
  };
  return (
    <>
      <Flex alignItems='flex-start' justifyContent='space-between' mb='32px'>
        <Box>
          <Subtitle color='deep_blue' mb='4px'>
            {data.name}
          </Subtitle>
          <ItemDetails icon='Location' value={data.address} />
          <ItemDetails icon='Call' value={data.country_code + data.phone} />
          <ItemDetails icon='Discovery' value={data.website} isLink />
        </Box>
        <PopoverMenu
          trigger={
            <img
              src={Menu}
              alt={data.name}
              height={16}
              width={4}
              style={{
                cursor: 'pointer'
              }}
            />
          }
          top='65px'
          left='10'
          w='234px'
          placement='right'
        >
          {(onClose: any) => (
            <Box fontSize='19px' fontWeight={500} color='gray'>
              <Flex
                onClick={() => {
                  onClose();
                  onRefModalOpen();
                }}
                py='14px'
                justifyContent='center'
                cursor='pointer'
                fontSize='19px'
              >
                Edit
              </Flex>
              <Flex
                onClick={() => {
                  onClose();
                  onDModalOpen();
                }}
                fontSize='19px'
                py='14px'
                justifyContent='center'
                cursor='pointer'
              >
                Delete
              </Flex>
            </Box>
          )}
        </PopoverMenu>
      </Flex>
      <ModalView
        isOpen={isDModalOpen}
        onClose={onDModalClose}
        bg='gray_50'
        maxW='600px'
        noCloseBtn
      >
        <Box bg='gray_50' px='86px' py='53px' borderRadius='8px'>
          <Text fontSize='19px' color='gray' mb='28px' textAlign='center'>
            Are you sure you want to permanently delete this referral?
          </Text>
          <Flex justifyContent='center' gap='16px'>
            <Button
              onClick={deleteReferral}
              bg='#FF7171'
              color='white'
              py='22px'
              px='32px'
            >
              YES
            </Button>
            <Button
              onClick={onDModalClose}
              bg={colors.accent}
              py='22px'
              px='32px'
              color='white'
            >
              NO
            </Button>
          </Flex>
        </Box>
      </ModalView>
      <ModalView
        isOpen={isRefModalOpen}
        onClose={onRefModalClose}
        bg='gray_50'
        maxW='600px'
        noCloseBtn
      >
        <Box bg='gray_50' px='86px' py='53px' borderRadius='8px'>
          <Headline size='19px' color='gray' mb='28px'>
            Rename file
          </Headline>
          <form onSubmit={editReferral}>
            <TextField
              label='Name'
              value={name}
              onChange={setName}
              bg='white'
              required={true}
            />
            <Flex>
              <TextField
                label='Country Code'
                value={countryCode}
                onChange={setCountryCode}
                bg='white'
                required={true}
              />
              <TextField
                label='Phone'
                value={phone}
                onChange={setPhone}
                bg='white'
                required={true}
              />
            </Flex>
            <TextField
              label='Address'
              value={address}
              onChange={setAddress}
              bg='white'
              required={true}
            />
            <TextField
              label='Website'
              value={website}
              onChange={setWebsite}
              bg='white'
              required={true}
            />
            <PrimaryButton
              label='Edit Referral'
              isLoading={isLoading}
              type={'submit'}
            />
            <TextButton label='cancel' mt='24px' onClick={onRefModalClose} />
          </form>
        </Box>
      </ModalView>
    </>
  );
};

export default ReferralItem;
