import {
  Box,
  Flex,
  Image,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Spinner
} from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import React, { useState, useEffect } from 'react';
import { colors } from '../../theme/colors';
import { getUserId, hexToRgbA } from '../../utils/helper';
import { Body, Caption } from '../Texts/Texts';
import { capitalize } from '../../utils/helper';
import { getFromUrl } from '../../api/Api';

interface ChatBubbleProps {
  chat: any;
  previousDate: string;
  type?: string;
  counsellor?: any;
}

const ChatBubble = ({ chat, type, counsellor }: ChatBubbleProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [media, setMedia] = useState<string | null>(null);

  const handleMedia = (mediaId: string) => {
    if (mediaId) {
      getFromUrl(`webhook/media/${mediaId}`, 'file').then((res) => {
        const objectUrl = window.URL.createObjectURL(res);
        setIsLoading(false);
        setMedia(objectUrl);
      });
    }
  };

  const downloadDocument = () => {
    if (media) window.open(media);
  };

  useEffect(() => {
    if (chat.type !== 'text') {
      if (chat.status === 'pending') {
        const objectUrl = window.URL.createObjectURL(chat[chat.type].media);
        setMedia(objectUrl);
      } else handleMedia(chat[chat.type].id);
    }
  }, []);

  const timeStamp = chat?.timestamp
    ? new Date(parseInt(chat.timestamp) * 1000).toTimeString().slice(0, 5)
    : '';

  const outbound =
    chat.direction === 'outbound' ||
    chat?.user === getUserId() ||
    chat?.user?._id === getUserId();

  return (
    <Flex justifyContent={outbound ? 'flex-end' : 'flex-start'} mb='8px'>
      {type === 'community' ? (
        <Box>
          <Box
            borderRadius='15px'
            borderBottomLeftRadius={outbound ? '15px' : '0px'}
            borderBottomRightRadius={outbound ? '0px' : '15px'}
            bg={outbound ? 'chat_gray' : hexToRgbA(colors.accent, '0.07')}
            p='16px'
            w='250px'
            mb='4px'
          >
            <Flex mb='12px' justifyContent='space-between'>
              <Caption color={outbound ? 'deep_blue' : 'warning'}>
                {outbound
                  ? capitalize(counsellor?.name)
                  : capitalize(chat?.user?.name)}
              </Caption>
            </Flex>

            {chat?.type === 'text' && <Body size='14px'>{chat?.message}</Body>}
          </Box>
        </Box>
      ) : (
        <Box>
          <Box
            borderRadius='15px'
            borderBottomLeftRadius={outbound ? '15px' : '0px'}
            borderBottomRightRadius={outbound ? '0px' : '15px'}
            bg={outbound ? 'chat_gray' : hexToRgbA(colors.accent, '0.07')}
            p='16px'
            w='250px'
            mb='4px'
          >
            <Flex mb='12px' justifyContent='space-between'>
              <Caption color={outbound ? 'deep_blue' : 'warning'}>
                {outbound
                  ? capitalize(counsellor?.name)
                  : capitalize(chat.author)}
              </Caption>
              <Caption color='gray'>
                {outbound ? 'Counsellor' : 'Client'}
              </Caption>
            </Flex>
            {(chat?.sticker || chat?.image) && media && (
              <Image
                src={media}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  onOpen();
                }}
              />
            )}
            {chat?.video && media && (
              <video
                controls={true}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  onOpen();
                }}
              >
                <source src={media} type='video/mp4' />
              </video>
            )}
            {chat?.text && <Body size='14px'>{chat?.text?.body}</Body>}
            {chat?.document && (
              <Body
                size='14px'
                fontWeight={600}
                bg={hexToRgbA(colors.gray, '0.1')}
                cursor='pointer'
                borderRadius={5}
                padding={5}
              >
                <Flex justifyContent='space-between' onClick={downloadDocument}>
                  <Text color={colors.deep_blue}>
                    {chat?.document?.caption}
                  </Text>
                  {isLoading ? (
                    <Spinner size='sm' color={colors.deep_blue} />
                  ) : (
                    <DownloadIcon color={colors.deep_blue} />
                  )}
                </Flex>
              </Body>
            )}
          </Box>
          <Caption color='gray' textAlign={outbound ? 'right' : 'left'}>
            {timeStamp}
          </Caption>
        </Box>
      )}{' '}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={'2xl'}
        motionPreset='scale'
        scrollBehavior='inside'
      >
        <ModalOverlay />
        <ModalContent padding={10} w='max-content'>
          <ModalCloseButton color={colors.gray} fontSize={12} />
          <ModalBody>
            {media ? (
              chat.type === 'image' || chat.type === 'sticker' ? (
                <Image src={media} />
              ) : chat.type === 'video' ? (
                <video
                  src={media}
                  controls={true}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    onOpen();
                  }}
                />
              ) : null
            ) : null}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};
export default ChatBubble;
