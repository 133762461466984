import * as topojson from 'topojson-client';
import topology from '../world-topo.json';
import { Box } from '@chakra-ui/react';
import { Mercator } from '@visx/geo';
import React from 'react';
export type GeoMercatorProps = {
  width: number;
  height: number;
  data: any;
};

interface FeatureShape {
  type: 'Feature';
  id: string;
  geometry: { coordinates: [number, number][][]; type: 'Polygon' };
  properties: { name: string };
}

export const background = '#FFFFFF';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const world = topojson.feature(topology, topology.objects.units) as {
  type: 'FeatureCollection';
  features: FeatureShape[];
};
const NationalityRatioMap = ({ width, height, data }: GeoMercatorProps) => {
  const centerX = width / 2;
  const centerY = height / 2;
  const scale = (width / 630) * 100;

  return width < 10 ? null : (
    <Box textAlign='center' margin='auto' width='100%'>
      <svg width={width} height={height} style={{ margin: 'auto' }}>
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          fill={background}
          rx={14}
        />
        <Mercator<FeatureShape>
          data={world.features}
          scale={scale}
          translate={[centerX, centerY + 50]}
        >
          {(mercator) => (
            <g>
              {mercator.features.map(({ feature, path }, i) => {
                if (data.includes(feature.properties.name))
                  return (
                    <path
                      key={`map-feature-${i}`}
                      d={path || ''}
                      fill={
                        data.includes(feature.properties.name)
                          ? '#5CDB94'
                          : '#05396B'
                      }
                      stroke={background}
                      strokeWidth={0.1}
                    />
                  );
              })}
            </g>
          )}
        </Mercator>
      </svg>
    </Box>
  );
};

export default NationalityRatioMap;
