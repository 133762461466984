import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';

const DashboardSkeleton = () => {
  return (
    <Box>
      <Grid templateColumns='repeat(3,1fr)' gap={6} paddingX={10}>
        <GridItem w='100%' className='skeleton' />
        <GridItem
          colSpan={2}
          w='100%'
          h='200'
          bg='grey.100'
          className='skeleton'
        />

        <GridItem w='100%' rowSpan={2}>
          <Flex flexDirection='column'>
            <Box
              w='100%'
              h='150'
              bg='grey.100'
              marginBottom={5}
              className='skeleton'
            />
            <Box w='100%' h='150' bg='gray.50' className='skeleton' />
          </Flex>
        </GridItem>
        <GridItem w='100%' h='100' bg='gray.50' className='skeleton' />
        <GridItem w='100%' h='100' bg='gray.50' className='skeleton' />
        <GridItem w='100%' h='200' bg='gray.50' className='skeleton' />
        <GridItem w='100%' h='200' bg='gray.50' className='skeleton' />
      </Grid>
    </Box>
  );
};
export default DashboardSkeleton;
