import { Cell, Legend, Pie, PieChart } from 'recharts';
import { colors } from '../../theme/colors';
import React, { useState } from 'react';
import CardHeader from '../Dashboard/CardHeader';
import { Box, Flex } from '@chakra-ui/react';
import useRangeChange from '../../hooks/useRangeChange';
import { getFromUrl } from '../../api/Api';
import { Indexable } from '../../interface';
import Card from '../Dashboard/Card';

const GenderRatioChart = () => {
  const [data, setData] = useState<any[]>([]);
  const { range, handleChange } = useRangeChange();
  const createChartData = (object: any) => {
    const graphData: any[] = [];
    for (const key in object) {
      if (key === 'OTHER' || key === 'null') continue;
      if (object[key] === 0) continue;
      const item = {
        name: key,
        value: (object as Indexable)[key]
      };
      graphData.push(item);
    }
    setData(graphData);
  };
  const getGenderRatio = async () => {
    try {
      const { result } = await getFromUrl(`users/gender/ratio?range=${range}`);
      createChartData(result);
    } catch (e: any) {
      return;
    }
  };
  React.useEffect(() => {
    getGenderRatio().catch((err) => console.error(err));
  }, [range]);

  return (
    <Card>
      <Box py='24px'>
        <CardHeader title='Gender Ratio' handleChange={handleChange} />
        {data.length ? (
          <PieChart width={300} height={300}>
            <Pie
              data={data}
              dataKey='value'
              nameKey='name'
              cx='50%'
              cy='50%'
              outerRadius={80}
              fill={colors.accent}
              isAnimationActive={true}
            >
              {data.map((entry, index) => (
                <Cell
                  fill={entry.name === 'FEMALE' ? colors.accent : '#F2AC57'}
                  key={`cell-${index}`}
                />
              ))}
            </Pie>
            <Legend
              verticalAlign='bottom'
              height={36}
              textDecoration='uppercase'
            />
          </PieChart>
        ) : (
          <Flex
            justifyContent='center'
            marginX='auto'
            marginY='40px'
            width='100%'
            color={colors.gray}
          >
            No data available
          </Flex>
        )}
      </Box>
    </Card>
  );
};

export default GenderRatioChart;
