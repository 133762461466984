import { Avatar, Flex } from '@chakra-ui/react';
import React from 'react';
import { Body } from '../Texts/Texts';

const UserItem = ({ user, options }: { user: any; options: any }) => {
  return (
    <Flex alignItems='center' mb='30px'>
      <Avatar
        name={user.name}
        color='white'
        boxSize='36px'
        getInitials={(name) => name.charAt(0)}
      />
      <Body color='deep_blue' ml='24px' mr='auto'>
        {user.email}
      </Body>
      {options}
    </Flex>
  );
};

export default UserItem;
