import React from 'react';
import { Box, Flex, useDisclosure, useToast } from '@chakra-ui/react';
import { PrimaryButton, TextButton } from '../../components/Buttons/Buttons';
import { Icon } from '../../components/Icons';
import { colors } from '../../theme/colors';
import { Headline } from '../../components/Texts/Texts';
import ModalView from '../../components/Modal';
import { PhoneInput, TextField } from '../../components/Inputs/TextField';
import ReferralItem from './ReferralItem';
import { noErrors } from '../../utils/helper';
import { getFromUrl, serverRequest } from '../../api/Api';

const Referrals = () => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [countryCode, setCountryCode] = React.useState('+234');
  const [referral, setReferral] = React.useState({
    name: '',
    address: '',
    website: '',
    phone: ''
  });
  const [referrals, setReferrals] = React.useState([]);

  const [disabled, setDisabled] = React.useState(true);
  const [submitLoading, setSubmitLoading] = React.useState(false);

  React.useEffect(() => {
    const emptyFields = noErrors(referral);
    setDisabled(emptyFields);
  }, [referral]);

  React.useEffect(() => {
    getReferrals().catch(() => {
      return;
    });
  }, []);

  const getReferrals = async () => {
    try {
      const referrals = await getFromUrl('referral/all');
      if (referrals !== undefined) setReferrals(referrals);
    } catch (e: any) {
      return;
    }
  };

  const handleReferral = (key: string, value: string) => {
    setReferral({
      ...referral,
      [key]: value
    });
  };

  const submitReferral = async () => {
    onClose();
    try {
      setSubmitLoading(true);
      await serverRequest({
        endpoint: 'referral',
        body: { ...referral, country_code: countryCode },
        method: 'POST'
      });
      getReferrals().catch(() => {
        return;
      });
      toast({
        position: 'top',
        title: 'Success',
        description: `You've successfully created referral ${referral.name}`,
        status: 'success',
        duration: 9000,
        isClosable: true
      });
      setReferral({
        ...referral,
        name: '',
        address: '',
        website: ''
      });
      setSubmitLoading(false);
    } catch (e: any) {
      setSubmitLoading(false);
    }
  };

  return (
    <Box
      bg='white'
      borderRadius='15px'
      padding='24px'
      boxShadow='0px 3.5px 5.5px rgba(0, 0, 0, 0.02)'
      h='calc(100vh - 244px)'
      overflowY='scroll'
      w='308px'
    >
      <Flex justifyContent='space-between' alignItems='center' mb='46px'>
        <Headline size='19px' color='deep_blue' fontWeight={700}>
          Referrals
        </Headline>
        <Icon
          name='Plus'
          color={colors.deep_blue}
          size={26}
          filled
          cursor='pointer'
          onClick={onOpen}
        />
      </Flex>
      <Box>
        {referrals?.map((item: any) => {
          return (
            <ReferralItem data={item} key={item._id} refetch={getReferrals} />
          );
        })}
      </Box>
      <ModalView maxW='600px' isOpen={isOpen} onClose={onClose} noCloseBtn>
        <Box px='86px' py='56px' bg='gray_50' borderRadius='8px'>
          <Headline color='gray' size='19px' textAlign='center' mb='24px'>
            Enter Name, Address, Phone Number and website of Referral
          </Headline>
          <TextField
            label='name'
            onChange={(value: string) => handleReferral('name', value)}
            bg='white'
            value={referral.name}
          />
          <TextField
            label='address'
            onChange={(value: string) => handleReferral('address', value)}
            bg='white'
            value={referral.address}
          />
          <PhoneInput
            label='phone'
            countryCode={countryCode}
            onChange={(value: string) => handleReferral('phone', value)}
            bg='white'
            value={referral.phone}
            handleCountry={setCountryCode}
          />
          <TextField
            label='website'
            onChange={(value: string) => handleReferral('website', value)}
            bg='white'
            value={referral.website}
          />
          <PrimaryButton
            label='add'
            onClick={submitReferral}
            mt='24px'
            disabled={disabled}
            isLoading={submitLoading}
          />
          <TextButton label='cancel' onClick={onClose} mt='24px' />
        </Box>
      </ModalView>
    </Box>
  );
};

export default Referrals;
