import { colors } from '../../theme/colors';
import DefaultCards from '../Dashboard/DefaultCard';
import React, { useState } from 'react';
import CardHeader from '../Dashboard/CardHeader';
import { Flex } from '@chakra-ui/react';
import Card from '../Dashboard/Card';
import { getFromUrl } from '../../api/Api';
import useRangeChange from '../../hooks/useRangeChange';

const NumberOfCases = ({ isDownloading }: { isDownloading: boolean }) => {
  const [noOfCases, setNoOfCases] = useState(0);
  const { range, handleChange } = useRangeChange();

  React.useEffect(() => {
    getNumberOfCases().catch(() => {
      return;
    });
  }, [range]);
  const getNumberOfCases = async () => {
    try {
      const numberOfCases = await getFromUrl(`cases/number?range=${range}`);
      setNoOfCases(numberOfCases.data);
    } catch (e: any) {
      return;
    }
  };

  return (
    <Card mb='16px' bg={isDownloading ? colors.light_green : colors.white}>
      <Flex
        h='auto'
        py='32px'
        w='100%'
        alignItems='center'
        flexDirection='column'
      >
        <CardHeader title='Number of Cases' handleChange={handleChange} />
        <DefaultCards
          isDownloading={isDownloading}
          no={noOfCases}
          text=''
          color={colors.dark_green}
          icon='Folder'
        />
      </Flex>
    </Card>
  );
};
export default NumberOfCases;
