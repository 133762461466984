import { Button, Flex, Text } from '@chakra-ui/react';
import React from 'react';

interface PrimaryButtonProps {
  label: string;
  height?: string;
  width?: string;
  background?: string;
  color?: string;
  isLoading?: boolean;
  [x: string]: any;
}

export const PrimaryButton = ({
  label,
  height,
  width,
  background,
  color,
  isLoading,
  ...rest
}: PrimaryButtonProps) => {
  return (
    <Button
      height={height ? height : '64px'}
      width={width ? width : '100%'}
      maxW='443px'
      background={background ? background : 'deep_blue'}
      fontFamily='Inter'
      fontWeight={500}
      color={color ? color : 'white'}
      isLoading={isLoading}
      borderRadius='12px'
      letterSpacing='1.25px'
      textTransform='uppercase'
      {...rest}
    >
      {label}
    </Button>
  );
};

interface OutlineButtonProps {
  label: string;
  height?: string;
  width?: string;
  background?: string;
  color?: string;
  isLoading?: boolean;
  [x: string]: any;
}

export const OutlineButton = ({
  label,
  height,
  width,
  color,
  isLoading,
  ...rest
}: OutlineButtonProps) => {
  return (
    <Button
      height={height ? height : '64px'}
      width={width ? width : '100%'}
      maxW='443px'
      background='transparent'
      fontFamily='Inter'
      fontWeight={500}
      color={color ? color : 'accent'}
      isLoading={isLoading}
      borderWidth='1px'
      borderColor={color ? color : 'accent'}
      borderRadius='12px'
      letterSpacing='1.25px'
      textTransform='uppercase'
      {...rest}
    >
      {label}
    </Button>
  );
};

interface IconButtonProps {
  label: string;
  icon: any;
  height?: string;
  width?: string;
  background?: string;
  color?: string;
  isLoading?: boolean;
  iconColor?: string;
  [x: string]: any;
}

export const IconButton = ({
  label,
  height,
  width,
  background,
  color,
  isLoading,
  icon,
  ...rest
}: IconButtonProps) => {
  return (
    <Button
      height={height ? height : '55px'}
      width={width ? width : '244px'}
      background={background ? background : 'accent'}
      fontFamily='Inter'
      fontWeight={500}
      color={color ? color : 'white'}
      isLoading={isLoading}
      borderRadius='12px'
      textTransform='uppercase'
      {...rest}
    >
      <Text fontSize='14px' mr='26px' letterSpacing='1.25px'>
        {label}
      </Text>
      {icon}
    </Button>
  );
};

interface TextButtonProps {
  label: string;
  color?: string;
  [x: string]: any;
}

export const TextButton = ({ label, color, ...rest }: TextButtonProps) => {
  return (
    <Flex justifyContent='center'>
      <Text
        color={color || 'deep_blue'}
        fontWeight={500}
        fontSize='14px'
        letterSpacing='1.25px'
        textTransform='uppercase'
        cursor='pointer'
        {...rest}
      >
        {label}
      </Text>
    </Flex>
  );
};
