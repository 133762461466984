import React from 'react';
import { AuthContext } from './AuthContext';

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [authenticated, setAuthenticated] = React.useState<any>(
    localStorage.getItem('access_token')
  );

  const signIn = (token: string, user: string, callback: VoidFunction) => {
    setAuthenticated(token);
    localStorage.setItem('access_token', token);
    localStorage.setItem('user', user);
    callback();
  };

  const signOut = (callback: VoidFunction = () => false) => {
    setAuthenticated(null);
    localStorage.removeItem('access_token');
    localStorage.removeItem('user');
    callback();
  };

  return (
    <AuthContext.Provider value={{ authenticated, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
