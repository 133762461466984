import { Flex } from '@chakra-ui/react';
import { colors } from '../../theme/colors';
import { hexToRgbA } from '../../utils/helper';
import IconBox from '../IconBox';
import { Body, Headline } from '../Texts/Texts';
import Card from './Card';

const TimeCard = ({
  no,
  isDownloading
}: {
  no: number;
  isDownloading?: boolean;
}) => {
  return (
    <Card
      h='auto'
      flexGrow={1}
      p='24px'
      w='20%'
      mw='148px'
      bg={isDownloading ? colors.light_green : colors.white}
    >
      <Flex
        flexDirection='column'
        justifyContent='space-between'
        alignItems='center'
        w='100%'
      >
        <IconBox
          isDownloading={isDownloading}
          boxSize='50px'
          icon='Time-Circle'
          iconColor={colors.deep_blue}
          bg={hexToRgbA(colors.deep_blue, '0.15')}
          iconSize={24}
        />
        <Headline size='16px' color='gray' fontWeight={400}>
          {no === 0 ? 'No data available' : no}
        </Headline>
        <Body size='16px' color='gray' letterSpacing='0.5px' textAlign='center'>
          Average Wait Time
        </Body>
      </Flex>
    </Card>
  );
};

export default TimeCard;
