import React from 'react';
import SidebarItem from './SidebarItem';

const Sidebar = () => {
  const menuItems = [
    {
      icon: 'Home',
      label: 'Dashboard',
      active: true,
      path: '/dashboard'
    },
    {
      icon: 'Folder',
      label: 'Cases',
      active: false,
      path: '/cases'
    },
    {
      icon: 'Chat',
      label: 'Sessions',
      active: false,
      path: '/sessions'
    },
    {
      icon: 'User2',
      label: 'Counsellors',
      active: false,
      path: '/counsellors'
    },
    {
      icon: 'Chart',
      label: 'Analytics',
      active: false,
      path: '/analytics'
    },
    {
      icon: 'Category',
      label: 'Resources',
      active: false,
      path: '/resources'
    },
    {
      icon: 'Setting',
      label: 'Accounts',
      active: false,
      path: '/accounts'
    }
  ];
  return (
    <>
      {menuItems.map((item, index) => {
        const { label, icon, path } = item;
        return (
          <SidebarItem
            label={label}
            icon={icon}
            path={path}
            key={label + index}
          />
        );
      })}
    </>
  );
};

export default Sidebar;
