import { Box, Flex, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { colors } from '../../theme/colors';
import { useSearchParams } from 'react-router-dom';
import { getFromUrl } from '../../api/Api';

const VerifyCommunityInvite = () => {
  const [params] = useSearchParams();

  useEffect(() => {
    const roomId = params.get('roomId');
    const token = params.get('token');
    const userObj = localStorage.getItem('user');
    const verifyInvite = (token: string, email: string, roomId: string) => {
      getFromUrl(
        `community/user/invitation?token=${token}&email=${email}&roomId=${roomId}`
      ).catch(() => {
        return;
      });
    };

    if (userObj && roomId && token) {
      const user = JSON.parse(userObj);
      verifyInvite(token, user.email, roomId);
    }
  }, []);

  return (
    <Flex
      alignItems='center'
      justifyContent='center'
      h='100vh'
      w='max-content'
      mx='auto'
    >
      <Text color={colors.deep_blue} fontSize={15} mr={4}>
        Verifying community invitation
      </Text>
      <Box className='loader'></Box>
    </Flex>
  );
};
export default VerifyCommunityInvite;
