import { Box, Flex, Grid, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { getFromUrl } from '../../api/Api';
import { PrimaryButton } from '../../components/Buttons/Buttons';
import CaseCard from '../../components/Cases/CaseCard';
import { Icon } from '../../components/Icons';
import { SearchInput } from '../../components/Inputs/TextField';
import PopoverMenu from '../../components/Popover';
import Tabs from '../../components/Tabs';
import { Body, Subtitle } from '../../components/Texts/Texts';
import DashboardLayout from '../../layouts/dashboard/DashboardLayout';
import { colors } from '../../theme/colors';
import { ICaseData } from '../../types';
import { hexToRgbA, searchText } from '../../utils/helper';
import EmptyCases from './EmptyCases';

const Cases = () => {
  const [data, setData] = useState<ICaseData[]>([]);
  const [activeTab, setActiveTab] = useState('timeline');
  const [filter, setFilter] = useState('all');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState<Array<ICaseData>>([]);

  const severityFilters = [
    'all',
    'mild',
    'moderate',
    'moderately severe',
    'severe'
  ];

  const applyFilter = () => {
    let endpoint;
    if (filter === 'all') {
      endpoint =
        activeTab === 'timeline' ? `cases?severity=ALL` : `cases/${activeTab}`;
      getCases(endpoint).catch(() => {
        return;
      });
    } else {
      const value = filter.split(' ').join('_').toUpperCase();
      endpoint =
        activeTab === 'timeline'
          ? `cases?severity=${value}`
          : `cases/filter/${activeTab}?severity=${value}`;
      getCases(endpoint).catch(() => {
        return;
      });
    }
  };

  const handleTabs = (tab: string) => {
    setFilter('all');
    let endpoint = '';
    if (tab === 'reported') {
      endpoint = 'cases/reported';
      setActiveTab('reported');
    } else if (tab === 'escalated') {
      endpoint = 'cases/escalated';
      setActiveTab('escalated');
    } else if (tab === 'assigned') {
      endpoint = 'cases/assigned?pageSize=10&&page=0';
      setActiveTab('assigned');
    } else {
      endpoint = 'cases?severity=ALL';
      setActiveTab('timeline');
    }

    getCases(endpoint).catch(() => {
      return;
    });
  };

  useEffect(() => {
    if (activeTab === 'timeline') {
      const intervalId = setInterval(() => {
        getCases('cases?severity=ALL').catch(() => {
          return;
        });
      }, 60000);
      return () => clearInterval(intervalId);
    }
  }, [activeTab]);

  useEffect(() => {
    getCases('cases?severity=ALL').catch(() => {
      return;
    });
  }, []);

  const filterByKeyword = useCallback(
    (dataList: Array<ICaseData>, keyword = ''): Array<ICaseData> => {
      if (keyword.length) {
        return dataList.filter((item: ICaseData) => {
          return (
            searchText(keyword, item.user.name) ||
            searchText(keyword, item.user.gender) ||
            searchText(keyword, item.severity) ||
            searchText(keyword, String(item.user.age))
          );
        });
      }
      return dataList;
    },
    []
  );

  useEffect(() => {
    if (searchKeyword && data) {
      setFilteredData(filterByKeyword(data, searchKeyword));
    } else {
      setFilteredData(data);
    }
  }, [searchKeyword, data, filterByKeyword]);

  const getCases = async (endpoint: string) => {
    setLoading(true);
    try {
      const cases = await getFromUrl(endpoint);
      const validCases = cases.filter(
        (caseItem: any) => caseItem.user !== null
      );
      setData(validCases);
    } catch (err) {
      return;
    }
    setLoading(false);
  };

  return (
    <DashboardLayout pageTitle='Cases'>
      <Box pr='60px' pb='54px'>
        <Flex justifyContent='space-between' alignItems='center' mb='30px'>
          <Tabs
            tabs={['timeline', 'escalated', 'reported', 'assigned']}
            activeTab={activeTab}
            tabAction={handleTabs}
          />
          <Flex alignItems='center'>
            <SearchInput
              mr='40px'
              value={searchKeyword}
              onChange={({ target: { value } }) => setSearchKeyword(value)}
            />
            <PopoverMenu
              placement='bottom-end'
              trigger={
                <Flex cursor='pointer' alignItems='center'>
                  <Icon
                    name='Filter'
                    color={colors.deep_blue}
                    size={18}
                    filled
                  />
                  <Subtitle color='deep_blue' ml='10px'>
                    Filter
                  </Subtitle>
                </Flex>
              }
              w='334px'
            >
              {(onClose: any) => (
                <Box px='8px' py='18px'>
                  <Body size='14px' color='black' textAlign='center' mb='22px'>
                    Show only Cases that are:
                  </Body>
                  {severityFilters.map((item, index) => {
                    const isSelected = item === filter;
                    return (
                      <Flex
                        h='48px'
                        justifyContent='center'
                        alignItems='center'
                        mb='8px'
                        bg={
                          isSelected
                            ? hexToRgbA(colors.black, '0.1')
                            : 'transparent'
                        }
                        pos='relative'
                        cursor='pointer'
                        onClick={() => setFilter(item)}
                        key={item + index}
                      >
                        <Subtitle textTransform='capitalize'>{item}</Subtitle>
                        {isSelected && (
                          <>
                            <Box
                              as='span'
                              className='iconlyBulk-Shield-Done'
                              pos='absolute'
                              right='24px'
                              fontSize='36px'
                            >
                              <Box
                                as='span'
                                className='path1'
                                display='none'
                              ></Box>
                              <Box
                                as='span'
                                className='path2'
                                color='dark_green'
                              ></Box>
                            </Box>
                          </>
                        )}
                      </Flex>
                    );
                  })}
                  <Box px='8px'>
                    <PrimaryButton
                      label='apply'
                      onClick={() => {
                        onClose();
                        applyFilter();
                      }}
                      height='55px'
                    />
                  </Box>
                </Box>
              )}
            </PopoverMenu>
          </Flex>
        </Flex>
        {!loading ? (
          <>
            {activeTab === 'timeline' && !filteredData?.length && (
              <EmptyCases />
            )}
            {activeTab === 'escalated' && !filteredData?.length && (
              <Text
                fontFamily='Inter, sans-serif'
                opacity={0.6}
                fontWeight={700}
                color='#756F86'
              >
                No escalated cases found
              </Text>
            )}
            {activeTab === 'reported' && !filteredData?.length && (
              <Text
                fontFamily='Inter, sans-serif'
                opacity={0.6}
                fontWeight={700}
                color='#756F86'
              >
                No reported cases found
              </Text>
            )}
            {activeTab === 'assigned' && !filteredData?.length && (
              <Text
                fontFamily='Inter, sans-serif'
                opacity={0.6}
                fontWeight={700}
                color='#756F86'
              >
                No assigned cases found
              </Text>
            )}

            <Grid gridTemplateColumns={'1fr 1fr 1fr'} gap='24px'>
              {filteredData?.map((item: any, index) => (
                <CaseCard
                  data={item}
                  key={item._id}
                  index={index}
                  items={filteredData}
                />
              ))}
            </Grid>
          </>
        ) : null}
      </Box>
    </DashboardLayout>
  );
};

export default Cases;
