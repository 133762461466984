import { Flex } from '@chakra-ui/react';
import React from 'react';
import { chatListTime } from '../../utils/helper';
import { Body, Caption, Subtitle } from '../Texts/Texts';

interface ChatListItemProps {
  active?: boolean;
  name: string;
  time?: string;
  type?: string;
  message: string;
  count?: string | number;
  onClick?: () => void;
}

const ChatListItem = ({
  active,
  name,
  time,
  message,
  count,
  onClick
}: ChatListItemProps) => {
  return (
    <Flex
      h='64px'
      pl='24px'
      pr='18px'
      py='8px'
      mb='15px'
      flexDirection='column'
      justifyContent='space-between'
      bg={active ? 'tint' : 'transparent'}
      cursor='pointer'
      onClick={onClick}
    >
      <Flex justifyContent='space-between'>
        <Body
          size='14px'
          fontWeight={700}
          color='deep_blue'
          textTransform='capitalize'
        >
          {name}
        </Body>
        {time && (
          <Caption color='black' opacity='0.6'>
            {chatListTime(time)}
          </Caption>
        )}
      </Flex>
      <Flex>
        <Subtitle
          size='14px'
          fontWeight={500}
          color='gray'
          w='260px'
          mr='auto'
          textOverflow='ellipsis'
          whiteSpace='nowrap'
        >
          {message}
        </Subtitle>
        <Flex
          boxSize='24px'
          bg={count ? 'accent' : 'transparent'}
          borderRadius='12px'
          justifyContent='center'
          alignItems='center'
        >
          {Boolean(count) && (
            <Caption color='white' fontWeight={700}>
              {count}
            </Caption>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ChatListItem;
