import { Box, Container, Grid, Image } from '@chakra-ui/react';
import auth_illustration from '../../assets/svg/auth_illustration.svg';
import itunu_logo from '../../assets/svg/itunu_logo.svg';
import { Body, Headline } from '../../components/Texts/Texts';

interface AuthLayoutProps {
  children: any;
  title: string;
  subtitle?: string;
  error?: string;
}

const AuthLayout = ({ children, title, subtitle, error }: AuthLayoutProps) => {
  return (
    <Container maxW='container.xl' h='100vh' py='32px'>
      <Image src={itunu_logo} h='56px' w='auto' />
      <Grid templateColumns={'1fr 1fr'}>
        <Box pt='64px' mx='auto' w='443px'>
          <Headline
            fontWeight={700}
            color='deep_blue'
            size='33px'
            mb={subtitle || error ? '4px' : '24px'}
          >
            {title}
          </Headline>
          {subtitle && (
            <Body color='gray' mb={error ? '4px' : '24px'}>
              {subtitle}
            </Body>
          )}
          {error && (
            <Body
              fontWeight={700}
              fontFamily='Inter, sans-serif'
              fontSize='12px'
              color='#FF7171'
              mb='24px'
            >
              {error}
            </Body>
          )}
          {children}
        </Box>
        <Box maxW='411px' mx='auto' pt='76px'>
          <Image src={auth_illustration} w='100%' h='auto' />
        </Box>
      </Grid>
    </Container>
  );
};

export default AuthLayout;
