import {
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import React, { SyntheticEvent, useRef, useState } from 'react';
import { colors } from '../../theme/colors';
import { SearchInput, TextField } from '../../components/Inputs/TextField';
import ChatListItem from '../../components/Sessions/ChatListItem';
import { Body, Caption, Headline } from '../../components/Texts/Texts';
import { Clip, Icon, Smiley } from '../../components/Icons';
import ChatBubble from '../../components/Sessions/ChatBubble';
import {
  IconButton,
  PrimaryButton,
  TextButton
} from '../../components/Buttons/Buttons';
import ModalView from '../../components/Modal';
import { getFromUrl, serverRequest } from '../../api/Api';
import CreateCommunity from './CreateCommunity';
import {
  capitalize,
  fileToDataUri,
  getMediaType,
  getUserId
} from '../../utils/helper';
import { useSocketNew } from '../../hooks/useSocketNew';
import Menu from '../../assets/svg/menu-blue.svg';
import PopoverMenu from '../../components/Popover';
import { fileSizes } from '../../utils/constants';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import EmojiPicker from 'emoji-picker-react';

interface CommunityInterface {
  _id: string;
  name: string;
  people: number;
}

const canSendMedia = (file: File) => {
  if (file) {
    const mediaType = getMediaType(file?.type);
    if (mediaType === 'document' && file.size > 5000000) return true;
    if (
      (mediaType === 'audio' || mediaType === 'video') &&
      file.size > 16000000
    )
      return true;
    if (mediaType === 'image' && file.size > 5000000) return true;
    if (mediaType === 'sticker' && file.size > 100000) return true;
  }
};

const CommunitySection = () => {
  const [showEmoji, toggleEmoji] = useState(false);
  const { send, recieve, connected, newMessage } = useSocketNew('community');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = React.useRef<null | HTMLDivElement>(null);
  const scrollViewRef = React.useRef<null | HTMLDivElement>(null);
  const [communities, setCommunities] = React.useState<CommunityInterface[]>(
    []
  );
  const [communityData, setCommunityData] = React.useState<CommunityInterface>(
    {} as CommunityInterface
  );
  const [userRooms, setUserRooms] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [roomMessages, setRoomMessages] = React.useState<any>([]);
  const [media, setMedia] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [caption, setCaption] = useState('');

  const toast = useToast();
  const {
    isOpen: isEditModalOpen,
    onOpen: onEditModalOpen,
    onClose: onEditModalClose
  } = useDisclosure();
  const {
    isOpen: isDelModalOpen,
    onOpen: onDelModalOpen,
    onClose: onDelModalClose
  } = useDisclosure();

  const {
    isOpen: isMediaModalOpen,
    onOpen: onMediaModalOpen,
    onClose: onMediaModalClose
  } = useDisclosure();
  React.useEffect(() => {
    getCommunities().catch((error) => console.error(error));
  }, []);

  const getProfile = async () => {
    try {
      const profile = await getFromUrl('users/profile');
      setUserRooms(profile.rooms);
    } catch (err: any) {
      return;
    }
  };
  const attachRef = useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    getProfile().catch(() => {
      return;
    });
  }, []);

  React.useEffect(() => {
    if (connected) {
      recieve('joined_room', () => {
        setLoading(false);
        if (communityData) {
          setUserRooms([...userRooms, communityData._id]);
        }
      });
      recieve('community_to_server', (dt) => {
        console.log('receiving', dt);
      });
      recieve('handle_errors', () => {
        return;
      });

      recieve('new_message', (dt) => {
        const userId = getUserId();

        if (
          userId !== dt.user &&
          dt.room === communityData._id &&
          Array.isArray(roomMessages)
        ) {
          setRoomMessages([...roomMessages, dt.message]);
          scrollToBottom();
        }
      });
    }
  }, [connected, recieve, communityData, userRooms]);

  React.useEffect(() => {
    if (newMessage) {
      setRoomMessages([...roomMessages, newMessage]);
      scrollToBottom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMessage]);

  const scrollToBottom = () => {
    return messagesEndRef.current?.scrollIntoView();
  };

  const scrollViewToBottom = () => {
    scrollViewRef.current?.scrollIntoView();
  };
  const editCommunity = (e: SyntheticEvent) => {
    e.preventDefault();
    setIsLoading(true);
    onEditModalClose();
    setName('');
    setDescription('');
    serverRequest({
      endpoint: `community/${communityData?._id}`,
      body: { name, description },
      method: 'PUT'
    })
      .then((res) => {
        getCommunities().catch((err) => console.error(err));
        setIsLoading(false);
        if (!res.error) {
          toast({
            position: 'top',
            title: 'Success',
            description: `Community successfully updated.`,
            status: 'success',
            duration: 3000,
            isClosable: true
          });
        } else {
          toast({
            position: 'top',
            title: 'Error',
            description: 'Error updating community',
            status: 'error',
            duration: 3000,
            isClosable: true
          });
        }
      })
      .catch((err) => console.error(err));
  };
  const deleteCommunity = () => {
    onDelModalClose();
    serverRequest({
      endpoint: `community/${communityData?._id}`,
      body: {},
      method: 'DELETE'
    })
      .then((res) => {
        if (!res.error) {
          toast({
            position: 'top',
            title: 'Success',
            description: `Community successfully deleted.`,
            status: 'success',
            duration: 3000,
            isClosable: true
          });
          if (communities.length) {
            setCommunityData(communities[0]);
          }
        } else {
          toast({
            position: 'top',
            title: 'Error',
            description: 'Error deleting community',
            status: 'error',
            duration: 3000,
            isClosable: true
          });
        }
      })
      .then(() => {
        getCommunities().catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  };

  const getCommunities = async () => {
    try {
      const communities = await getFromUrl('community/rooms');
      setCommunities(communities.rooms);
    } catch (e: any) {
      console.error(e.message);
    }
  };

  React.useEffect(() => {
    if (communities.length) {
      if (Object.keys(communityData).length) {
        setCommunityData(
          communities.filter(
            (community) => community._id === communityData._id
          )[0]
        );
        return;
      }
      setCommunityData(communities[0]);
    }
  }, [communities]);

  React.useEffect(() => {
    if (communityData) {
      const getRoomMessages = async () => {
        try {
          const roomMessages = await getFromUrl(
            `community/${communityData._id}/messages`
          );
          setRoomMessages(roomMessages);
        } catch (e: any) {
          return;
        }
      };

      getRoomMessages().catch(() => {
        return;
      });
    }
  }, [communityData]);
  const sendAttachment = () => {
    onMediaModalClose();
    setCaption('');
    setFile(null);
  };

  const joinCommunity = () => {
    setLoading(true);
    const user = localStorage.getItem('user');
    if (user) {
      const userObj = JSON.parse(user);
      serverRequest({
        endpoint: `community/invite/${userObj._id}?name=${communityData?.name}`,
        body: {},
        method: 'PUT'
      }).then(() => {
        setLoading(false);
        toast({
          position: 'top',
          title: 'Success',
          description: 'A community invite has been sent to your email',
          status: 'success',
          duration: 3000,
          isClosable: true
        });
        getProfile().catch(() => {
          return;
        });
      });
    }
  };
  const addAttachment = (e: any) => {
    const attachment = e?.target?.files[0];
    if (attachment) {
      setFile(attachment);

      fileToDataUri(attachment).then(async (dataUri: any) => {
        const blob = new Blob([dataUri]);
        const objectUrl = window.URL.createObjectURL(blob);
        setMedia(objectUrl);
      });
      onMediaModalOpen();
    }
    e.target.value = '';
  };

  const handleKeyPress = (key: string) => {
    if (key === 'Enter') {
      sendMessage();
    }
  };

  const sendMessage = () => {
    const data = {
      room: communityData?.name,
      user: getUserId(),
      type: 'text',
      message
    };
    send('community_to_server', data);
    setRoomMessages([...roomMessages, data]);
    setMessage('');
    scrollToBottom();
  };

  return (
    <Grid gridTemplateColumns='1fr 2.4fr'>
      <Flex flexDirection='column' h='calc(100vh - 130px)'>
        <Box pr='30px' mt='24px'>
          <SearchInput mb='16px' />
          <IconButton
            label='Add Community'
            icon={
              <Icon name='Category' color={colors.white} size={20} filled />
            }
            onClick={onOpen}
          />
        </Box>
        <Box flex={1} overflowY='scroll' mt='20px' ml='-24px' pr='18px'>
          {communities.length
            ? communities?.map((item, index) => {
                const { _id, name, people } = item;
                return (
                  <ChatListItem
                    type='community'
                    key={_id ? _id : index}
                    name={name}
                    message={people === 1 ? '1 Person' : `${people} People`}
                    count={0}
                    onClick={() => {
                      setCommunityData(item);
                      setRoomMessages([]);
                    }}
                  />
                );
              })
            : null}
        </Box>
      </Flex>
      <Flex
        flexDirection='column'
        h='calc(100vh - 84px)'
        mt='-46px'
        borderLeft={`1px solid ${colors.shade}`}
      >
        <Flex
          bg='white'
          h='80px'
          px='44px'
          alignItems='center'
          justifyContent='space-between'
        >
          {communityData && (
            <Box>
              <Headline
                size='19px'
                color='deep_blue'
                fontWeight={500}
                textTransform='capitalize'
              >
                {communityData.name}
              </Headline>
              <Caption color='gray'>
                {communityData.people === 1
                  ? '1 Person'
                  : `${communityData.people} People`}
              </Caption>
            </Box>
          )}
          <PopoverMenu
            trigger={
              <Image
                src={Menu}
                alt={`edit ${communityData?.name} community`}
                width={4}
                height={16}
                boxSize={4}
                cursor='pointer'
              />
            }
            top='65px'
            left='10'
            w='234px'
            placement='right'
          >
            {(onClose: any) => (
              <Box fontSize='19px' fontWeight={500} color='gray'>
                <Flex
                  onClick={() => {
                    onClose();
                    onEditModalOpen();
                  }}
                  py='14px'
                  justifyContent='center'
                  cursor='pointer'
                  fontSize='19px'
                >
                  Edit
                </Flex>
                <Flex
                  onClick={() => {
                    onClose();
                    onDelModalOpen();
                  }}
                  fontSize='19px'
                  py='14px'
                  justifyContent='center'
                  cursor='pointer'
                >
                  Delete
                </Flex>
              </Box>
            )}
          </PopoverMenu>
        </Flex>
        {communityData && (
          <>
            <Box
              ref={messagesEndRef}
              flex={1}
              overflowY='scroll'
              py='20px'
              px='64px'
            >
              {roomMessages.length
                ? roomMessages?.map((message: any, index: number) => {
                    return (
                      <ChatBubble
                        chat={message}
                        previousDate={
                          (roomMessages as any)[index - 1]?.created_at
                        }
                        key={message?._id ? message?._id : index}
                        type='community'
                      />
                    );
                  })
                : null}
              {/* <Box ref={messagesEndRef} /> */}
            </Box>
            <Flex
              bg='white'
              minH='86px'
              pr='38px'
              pl='18px'
              pt='8px'
              alignItems='flex-start'
            >
              {(userRooms as string[]).includes(communityData._id) ? (
                <>
                  <Flex
                    height='50px'
                    alignItems='center'
                    mr='28px'
                    cursor='pointer'
                    onClick={() => toggleEmoji(!showEmoji)}
                  >
                    <Smiley />
                  </Flex>
                  <Textarea
                    minH='50px'
                    maxH='150px'
                    flexGrow={1}
                    placeholder='Write a message...'
                    mr='30px'
                    border='none'
                    _focus={{
                      outline: 'none',
                      boxShadow: 'none'
                    }}
                    resize='none'
                    pt='15px'
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                    onKeyPress={(e) => handleKeyPress(e.key)}
                  ></Textarea>
                  <Flex height='50px' alignItems='center'>
                    <Input
                      type='file'
                      id='attach-file'
                      accept='image/jpeg, image/png,video/mp4,video/3gp,image/webp,text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,audio/aac, audio/mp4, audio/mpeg, audio/amr, audio/ogg,video/3gp'
                      ref={attachRef}
                      display='none'
                      onChange={addAttachment}
                    />
                    <span
                      onClick={() => {
                        if (attachRef.current !== null)
                          attachRef.current.click();
                      }}
                    >
                      <Clip />
                    </span>
                    <Flex
                      boxSize='40px'
                      borderRadius='20px'
                      bg='deep_blue'
                      ml='30px'
                      justifyContent='center'
                      alignItems='center'
                      cursor='pointer'
                      onClick={() => {
                        if (message.trim().length) sendMessage();
                      }}
                    >
                      <Icon name='Send' filled color='#ffffff' />
                    </Flex>
                  </Flex>
                </>
              ) : (
                <Flex
                  justifyContent='space-between'
                  alignItems='center'
                  h='100%'
                  w='100%'
                  px='50px'
                >
                  <Body size='14px'>
                    Join community to start having conversations with other
                    counsellors.
                  </Body>
                  <PrimaryButton
                    label='Join'
                    height='46px'
                    width='130px'
                    isLoading={loading}
                    onClick={joinCommunity}
                  />
                </Flex>
              )}
            </Flex>
            {showEmoji ? (
              <EmojiPicker
                width='100%'
                height={300}
                searchDisabled={true}
                previewConfig={{
                  showPreview: false
                }}
                onEmojiClick={({ emoji }) => setMessage(message + emoji)}
              />
            ) : null}
          </>
        )}
      </Flex>
      <ModalView maxW='600px' isOpen={isOpen} onClose={onClose} noCloseBtn>
        <CreateCommunity
          onClose={onClose}
          getCommunities={getCommunities}
          setCommunityData={setCommunityData}
          getProfile={getProfile}
        />
      </ModalView>
      <ModalView
        isOpen={isDelModalOpen}
        onClose={onDelModalClose}
        bg='gray_50'
        maxW='600px'
        noCloseBtn
      >
        <Box bg='gray_50' px='86px' py='53px' borderRadius='8px'>
          <Text fontSize='19px' color='gray' mb='28px' textAlign='center'>
            {`Are you sure you want to permanently delete the ${communityData?.name} community?`}
          </Text>
          <Flex justifyContent='center' gap='16px'>
            <Button
              onClick={deleteCommunity}
              bg='#FF7171'
              color='white'
              py='22px'
              px='32px'
            >
              YES
            </Button>
            <Button
              onClick={onDelModalClose}
              bg={colors.accent}
              py='22px'
              px='32px'
              color='white'
            >
              NO
            </Button>
          </Flex>
        </Box>
      </ModalView>
      <ModalView
        isOpen={isEditModalOpen}
        onClose={onEditModalClose}
        bg='gray_50'
        maxW='600px'
        noCloseBtn
      >
        <Box bg='gray_50' px='86px' py='53px' borderRadius='8px'>
          <Headline size='19px' color='gray' mb='28px'>
            Update Community
          </Headline>
          <form onSubmit={editCommunity}>
            <TextField
              label='Name'
              value={name}
              onChange={setName}
              bg='white'
              required={true}
            />
            <TextField
              label='Description'
              value={description}
              onChange={setDescription}
              bg='white'
              required={true}
            />
            <PrimaryButton
              label='Edit Community'
              isLoading={isLoading}
              type={'submit'}
            />
            <TextButton label='cancel' mt='24px' onClick={onEditModalClose} />
          </form>
        </Box>
      </ModalView>
      <Modal
        isOpen={isMediaModalOpen}
        onClose={onMediaModalClose}
        size={'2xl'}
        scrollBehavior={'inside'}
      >
        <ModalOverlay />

        <ModalContent>
          {file !== null && canSendMedia(file) && (
            <ModalHeader>{`${capitalize(
              getMediaType(file.type)
            )} size must not be larger than ${
              fileSizes[getMediaType(file.type)]
            }`}</ModalHeader>
          )}
          <ModalCloseButton color={colors.deep_blue} />
          <ModalBody paddingX={12} paddingTop={12}>
            {file &&
              media &&
              (getMediaType(file?.type) === 'image' ||
                getMediaType(file?.type) === 'sticker') && (
                <Image src={media} />
              )}
            {file && media && getMediaType(file?.type) === 'video' && (
              <video src={media} controls />
            )}
            {file && media && getMediaType(file?.type) === 'document' && (
              <Box width={'100%'} bg={colors.deep_blue} borderRadius={10}>
                <Text
                  padding={10}
                  color={colors.white}
                  w={'max-content'}
                  m={'auto'}
                >
                  {file.name}
                </Text>
              </Box>
            )}
          </ModalBody>
          <ModalFooter display='flex' alignItems={'center'} paddingX={12}>
            {file &&
              ['image', 'document', 'sticker', 'video'].includes(
                getMediaType(file.type)
              ) && (
                <>
                  <Text flex={1} color={colors.deep_blue}>
                    Add Caption
                  </Text>
                  <Input
                    borderColor={colors.deep_blue}
                    flex={3}
                    value={caption}
                    onChange={(e) => setCaption(e.target.value)}
                  />
                </>
              )}
            <Button
              rightIcon={<ArrowForwardIcon />}
              colorScheme='blue'
              variant='solid'
              // flex={1}
              ml={2}
              justifySelf={'flex-end'}
              isDisabled={file !== null && canSendMedia(file)}
              onClick={sendAttachment}
            >
              Send
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Grid>
  );
};

export default CommunitySection;
