import moment from 'moment';
import { Indexable } from '../interface';
import { mediaTypes } from './constants';

export const hexToRgbA = (hex: string, opacity: string) => {
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;
    // eslint-disable-next-line no-bitwise
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
      ','
    )},${opacity})`;
  }
  throw new Error('Bad Hex');
};

export const range = (start: number, end: number) => {
  const length = end - start + 1;
  /*
  	Create an array of certain length and set the elements within it from
    start value to end value.
  */
  return Array.from({ length }, (_, idx) => idx + start);
};

export const noErrors = (obj: any) => Object.values(obj).some((x) => x === '');

export const formatTime = (time: Date | string) => {
  const formattedTime = moment(time).format('h:mm A');

  return formattedTime;
};

export const chatListTime = (time: Date | string) => {
  const today = moment().format('DD/MM/YYYY');
  const day = moment(time).format('DD/MM/YYYY');

  if (today === day) {
    return moment(time).format('h:mm a');
  }
  if (day === moment().add(-1, 'days').format('DD/MM/YYYY')) {
    return 'Yesterday';
  }
  return day;
};

export const getUserId = () => {
  const user = localStorage.getItem('user');
  if (user) {
    const id = JSON.parse(user)._id;
    return id;
  }
};

export const getFlag = (code: string) => {
  if (code) {
    const lowerCaseCode = code.toLowerCase();
    return `https://flagcdn.com/w160/${lowerCaseCode}.png`;
  }
};

export const searchText = (keyword: string, targetText = '') => {
  return targetText
    .toLowerCase()
    .includes(keyword.toLowerCase().trim().replace('  ', ' '));
};

export const downloadImage = (blob: any, fileName: string) => {
  const downloadLink = window.document.createElement('a');
  downloadLink.download = fileName;
  downloadLink.href = blob;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  downloadLink.remove();
};

export const createGraphData = (object: any) => {
  const graphData = [];
  for (const key in object) {
    const item = {
      name: key,
      value: (object as Indexable)[key]
    };
    graphData.push(item);
  }
  return graphData;
};

export const getMediaType = (fileType: string) => {
  switch (true) {
    case mediaTypes.imageTypes.includes(fileType):
      return 'image';
    case mediaTypes.documentTypes.includes(fileType):
      return 'document';
    case mediaTypes.videoTypes.includes(fileType):
      return 'video';
    case mediaTypes.audioTypes.includes(fileType):
      return 'audio';
    case mediaTypes.stickerTypes.includes(fileType):
      return 'sticker';
    default:
      return 'text';
  }
};

export const fileToDataUri = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader> | null) => {
      resolve(event?.target?.result);
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsArrayBuffer(file);
  });

export const capitalize = (word: string) => {
  if (word) {
    const firstLetter = word[0].toUpperCase();
    const remainingLetters = word.slice(1, word.length);
    return firstLetter + remainingLetters;
  }
};
