import React from 'react';
import { Body } from '../Texts/Texts';

interface DetailsItemProps {
  label: string;
  value: string;
}

const DetailsItem = ({ label, value }: DetailsItemProps) => {
  return (
    <>
      <Body size='14px' color='gray'>
        {label}
      </Body>
      <Body size='16px' color='black' fontWeight={700}>
        {value}
      </Body>
    </>
  );
};

export default DetailsItem;
