import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { usePagination, DOTS } from '../hooks/usePagination';

interface PaginationProps {
  onPageChange: (arg: number | string) => void;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
}

const Pagination = (props: PaginationProps) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });
  return (
    <Flex justifyContent='center' py='20px'>
      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return (
            <Box color='deep_blue' mx='4px' fontWeight={500} key={pageNumber}>
              &#8230;
            </Box>
          );
        }
        const activePage = pageNumber === currentPage;
        return (
          <Flex
            key={pageNumber}
            mx='4px'
            bg={activePage ? 'deep_blue' : 'transparent'}
            color={activePage ? 'white' : 'deep_blue'}
            cursor='pointer'
            h='24px'
            w='24px'
            borderRadius='12px'
            justifyContent='center'
            alignItems='center'
            onClick={() => onPageChange(pageNumber)}
            fontWeight={500}
          >
            {pageNumber}
          </Flex>
        );
      })}
    </Flex>
  );
};

export default Pagination;
