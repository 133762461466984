import { Box, Flex } from '@chakra-ui/react';
import { ICounsellor } from 'types';
import Pagination from '../Pagination';
import { Body } from '../Texts/Texts';
import CouncellorTableItem from './CounsellorTableItem';

const CounsellorsTable = ({
  counsellors,
  counsellorsCount,
  actionItem,
  currentPage,
  onPageChange,
  scroll,
  searchText
}: {
  searchText?: string;
  counsellors: any[];
  counsellorsCount: number;
  currentPage: number;
  onPageChange: (arg: number) => void;
  actionItem?: any;
  scroll?: boolean;
  filterCounsellors?: string;
}) => {
  const PAGE_SIZE = 10;
  const TOTAL_PAGES = counsellorsCount;

  return (
    <Box>
      <Flex mb='16px' px='22px'>
        <Body size='14px' fontWeight={700} flex={1} color='gray'>
          Name
        </Body>
        <Body size='14px' fontWeight={700} flex={1} color='gray'>
          Email
        </Body>
        <Body size='14px' fontWeight={700} flex={1} color='gray'>
          Sessions
        </Body>
      </Flex>
      <Box
        maxH={scroll ? '420px' : 'auto'}
        overflow={scroll ? 'scroll' : 'visible'}
      >
        {counsellors.map((item: ICounsellor, index) => {
          return (
            <CouncellorTableItem
              key={item._id}
              counsellor={item}
              isEven={Boolean(index % 2 === 0)}
              actionItem={actionItem}
            />
          );
        })}
      </Box>
      {searchText && searchText.length ? null : (
        <Pagination
          totalCount={TOTAL_PAGES}
          pageSize={PAGE_SIZE}
          currentPage={currentPage}
          onPageChange={(page) => onPageChange(page as number)}
          siblingCount={2}
        />
      )}
    </Box>
  );
};
export default CounsellorsTable;
