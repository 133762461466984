import { Flex, Spacer, Stack, Text } from '@chakra-ui/react';
import moment from 'moment';

interface INotificationItemProps {
  children: React.ReactNode;
  date: string | any;
}

const NotificationItem = ({ children, date }: INotificationItemProps) => {
  return (
    <Stack
      maxW='90%'
      mx='auto'
      py='12px'
      px='20px'
      borderRadius='6px'
      border='1px solid #EBF4F8'
      background='#FFFFFF'
      mb='8px'
    >
      <Text>{children}</Text>
      <Flex>
        <Spacer></Spacer>
        <Text color='#756F86' fontSize='12px'>
          {moment(date).from(new Date())}
        </Text>
      </Flex>
    </Stack>
  );
};

export default NotificationItem;
