const version = 'api/v1';

export const base_url = process.env.REACT_APP_BASE_URL;

export const getFromUrl = async (endpoint: string, type?: string) => {
  const token = localStorage.getItem('access_token');
  if (token) {
    try {
      const res = await fetch(`${base_url}/${version}/${endpoint}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '*',
          'Bypass-Tunnel-Reminder': '*'
        }
      });
      if (type === 'file') {
        const data = await res.blob();
        return data;
      }
      const data = await res.json();
      return data;
    } catch (err) {
      console.error(err);
    }
  }
};

export const serverRequest = async ({
  endpoint,
  body,
  method,
  type
}: {
  endpoint: string;
  body: any;
  method: string;
  type?: string;
}) => {
  const headers: { [key: string]: any } = {
    'ngrok-skip-browser-warning': '*',
    'Bypass-Tunnel-Reminder': '*'
  };

  const token = localStorage.getItem('access_token');
  if (type !== 'formData') headers['Content-Type'] = 'application/json';
  if (token) headers.Authorization = `Bearer ${token}`;

  const config = {
    method,
    body: body ? (type === 'formData' ? body : JSON.stringify(body)) : null,
    headers
  };
  try {
    const response = await fetch(`${base_url}/${version}/${endpoint}`, {
      ...config
    });
    if (!response.ok) return response;
    if (type === 'file') {
      const data = await response.blob();
      return data;
    }
    const data = await response.json();
    return data;
  } catch (err: any) {
    return;
  }
};
